






















import { computed, createComponent, ref, Ref } from "@vue/composition-api"
import { parseISO } from "date-fns"
import ClientAppointment from "@/components/client/appointments-client.vue"
import { listAppointments } from "@/api/appointments"
import { Appointment, Maybe } from "@/generated/graphql"

export default createComponent({
  components: {
    ClientAppointment
  },
  setup(_props, { root }) {
    const appointments = ref([]) as Ref<Maybe<Appointment[]>>
    const loading = ref(true)
    const user = computed(() => root.$store.state.auth.user)
    const timeBuffer = 600000 // 10 mins buffer for the appointments. if over 10 mins difference from current time, appointment won't show. 

    root.$nextTick(async () => {
      appointments.value = await listAppointments({ userIds: [user.value.id] })

      let timeStamp = new Date()
      timeStamp.setTime(timeStamp.getTime() - timeBuffer)

      appointments.value = appointments.value.filter(appointment => {
        const appointmentDate = parseISO(appointment.date + "T" + appointment.time)
        return appointmentDate > timeStamp}
      )

      loading.value = false
    })

    return { appointments, user, loading }
  }
})
