import { query } from "@/api"
import { MenuServiceCategory } from "@/generated/graphql"
import gql from "graphql-tag"

export async function listNailartCategory(): Promise<Array<MenuServiceCategory>> {
  const response = await query({
    query: gql`
      query MenuNailartCategory {
        menuNailartCategory {
          id
          title
          services {
            id
            duration
            type
            title
            description
            inserted_at
            packs {
              id
              quantity
              price
            }
          }
        }
      }
    `
  })
  return response.data.menuNailartCategory[0]
}
