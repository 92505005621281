





























import { computed, createComponent, ref } from "@vue/composition-api"
import { changePassword } from "@/api/users"

export default createComponent({
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props, { root }) {
    const loadingRef = ref(true)
    const messageRef = ref("")
    const passwordRef = ref("")
    const isSubmittingRef = ref(false)
    const user = computed(() => root.$store.state.auth.user)

    async function onLoad() {
      const { successful } = await root.$store.dispatch("auth/signIn", { code: props.id })

      loadingRef.value = false
      if (!successful) {
        messageRef.value = "This password reset link has expired."
        return
      }
    }
    onLoad()

    async function onSubmit() {
      isSubmittingRef.value = true
      const { successful, messages } = await changePassword({ id: user.value.id, password: passwordRef.value })
      isSubmittingRef.value = false
      if (successful) {
        root.$message({ type: "success", message: "Password changed." })
        root.$router.push({ name: "*-homepage" })
      } else {
        root.$message({ type: "error", message: messages[0].message })
      }
    }

    return {
      isSubmittingRef,
      loadingRef,
      messageRef,
      passwordRef,
      onSubmit
    }
  }
})
