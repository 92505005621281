






































































import { createComponent, ref, Ref, watch, computed } from "@vue/composition-api"
import AvailableTime from "@/components/client/artists-show-available-time.vue"
import { getPublicArtist } from "@/api/artists"
import { Artist, Maybe, PossibleAppointment } from "@/generated/graphql"
import { startOfToday, formatISO } from "date-fns"
import { encodeDateScalar, timeScalarToQuarterHour, quarterHourToLabel } from "@/utils/date"
import { listPossibleAppointments } from "@/api/appointments"
import { listServices } from "@/api/menu_services"
import { listLocations } from "@/api/locations"

export default createComponent({
  components: {
    AvailableTime
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const artist = ref(null) as Ref<Maybe<Artist>>
    const date = ref(startOfToday())
    const appointments = ref([]) as Ref<Array<PossibleAppointment>>
    const availableTimes = ref([])
    const services = ref([])
    const availableLocationIds = ref([])
    const locations = ref([])
    const currentLocationId = ref(null) as any
    const showLocations = ref(true)
    const isPossibleAppointmentsLoading = ref(true)

    async function initialLoad() {
      artist.value = await getPublicArtist(props.id)
      services.value = await listServices()
      locations.value = await listLocations()
      availableLocationIds.value =
        [...new Set(artist.value?.workSchedules.map(ws => ws.location.id).flat())]
    }
    initialLoad()

    const availableLocations = computed(() => {
      return locations.value.filter(l => availableLocationIds.value.includes(l.id))
    })

    const chooseLocation = (id: any) => {
      currentLocationId.value = id
    }

    const nextStep = async () => {
      showLocations.value = false
    }

    const fetchAppointments = async () => {
      isPossibleAppointmentsLoading.value = true
      const dateValue = date.value as Date
      const classicMani = services.value.find(s => s.slug === "classic_mani")
      const newDate = formatISO(dateValue, { representation: "date" })
      appointments.value = await listPossibleAppointments(
        [classicMani.id],
        true,
        currentLocationId.value,
        [props.id],
        { date: newDate }
      )
      isPossibleAppointmentsLoading.value = false
    }

    watch(
      () => [props.id, showLocations.value, date.value],
      () => {
        if (props.id && showLocations.value === false) {
          fetchAppointments()
        }
      }
    )

    watch([appointments, date, currentLocationId], ([appointments, date, currentLocationId]) => {
      if (!appointments && !currentLocationId) {
        return
      }
      const dateScalar = encodeDateScalar(date)
      const today = (appointments as Array<PossibleAppointment>).filter(a => a.date === dateScalar)
      availableTimes.value = today.map(a => {
        const qh = timeScalarToQuarterHour(a.time)
        return quarterHourToLabel(qh)
      })
    })

    return {
      artist,
      date,
      availableTimes,
      encodeDateScalar,
      showLocations,
      currentLocationId,
      chooseLocation,
      nextStep,
      availableLocations,
      isPossibleAppointmentsLoading
    }
  }
})
