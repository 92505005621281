































import { createComponent, reactive, ref, Ref, toRefs, watch } from "@vue/composition-api"
import { getUser } from "@/api/users"
import { Maybe, User } from "@/generated/graphql"
import { chargeItem } from "@/api/purchases"

export default createComponent({
  props: {
    id: String
  },
  setup(props, { root }) {
    const user: Ref<Maybe<User>> = ref(null)
    const state = reactive({
      form: {
        amount: 10,
        description: ""
      },
      errors: {
        amount: "",
        description: ""
      },
      submitting: false
    })

    watch(async () => {
      user.value = await getUser(props.id)
    })

    async function submitCharge() {
      if (state.form.amount < 5 || state.form.amount > 2000) {
        root.$alert("Must be between $5-2000 USD.", "Warning", {
          confirmButtonText: "OK"
        })
        return
      }
      if (state.form.description.trim() === "") {
        root.$alert("Please provide a detailed item description.", "Warning", {
          confirmButtonText: "OK"
        })
        return
      }
      state.submitting = true
      const { successful, result, messages } = await chargeItem({
        userId: user.value.id,
        amount: state.form.amount * 100,
        description: state.form.description
      })
      state.submitting = false
      if (successful) {
        root.$alert(`Successfully charged for ${(result.amount / 100).toFixed(2)}.`, "Success", {
          confirmButtonText: "OK",
          callback() {
            root.$router.push({ name: "!-users-show", params: { id: user.value.id } })
          }
        })
      } else {
        root.$alert(messages[0].message, "Charge failed", {
          confirmButtonText: "OK"
        })
      }
    }

    return { submitCharge, user, ...toRefs(state) }
  }
})
