
















































































































import { computed, createComponent, ref, onMounted } from "@vue/composition-api"
import TileService from "@/components/client/tile-service.vue"
import MenuManicures from "@/components/client/menu-manicures.vue"
import MenuPedicures from "@/components/client/menu-pedicures.vue"
import { listServiceCategories } from "@/api/menu_service_categories"
import { listLocations } from "@/api/locations"

export default createComponent({
  components: {
    TileService,
    MenuManicures,
    MenuPedicures
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  setup(props, { root }) {
    const serviceCategories = ref([])
    const manicureServices = ref([])
    const pedicureServices = ref([])
    const locations = ref([])
    const currentLocationId = ref(null)
    const isArtistSelected = !!root.$route.query.onlyArtistId // true if yes, false if not
    const showLocations = ref(!isArtistSelected)

    const maniDrawerRef = ref(null)
    const pediDrawerRef = ref(null)
    const viewer = computed(() => root.$store.state.auth.user)

    const maniPediCombined = ref("same") // ref(root.$store.state.appointments.sameArtist ? "same" : "different")
    const persons = ref(0)
    const personsObject = ref({mani: 0, pedi: 0})

    onMounted(() => {
      fetchServiceCategories()
    })

    async function fetchServiceCategories() {
      serviceCategories.value = await listServiceCategories()
      locations.value = await listLocations()
      const firstLocation = locations.value[0]
      currentLocationId.value = localStorage.getItem("locationId") || firstLocation.id
      manicureServices.value = getServicesBy("Manicure")
      pedicureServices.value = getServicesBy("Pedicure")
    }


    function getServicesBy(type) {
      const manisOrPedis = serviceCategories.value.find(category => category.title === type)
      return manisOrPedis?.services.map(service => {
        return {
          id: service.id,
          title: service.title,
          description: service.description,
          duration: service.duration,
          value: 0,
          inserted_at: service.inserted_at
        }
      })
    }

    const chooseLocation = (id: any) => {
      currentLocationId.value = id
      localStorage.setItem("locationId", id)
    }
    const nextStep = () => {
      showLocations.value = false
    }

    const totalManis = computed(() => {
      const opts = manicureServices.value
      let total = 0
      for (let i = 0; i < opts.length; i++) {
        total += opts[i].value
      }
      return total
    })
    const totalPedis = computed(() => {
      const opts = pedicureServices.value
      let total = 0
      for (let i = 0; i < opts.length; i++) {
        total += opts[i].value
      }
      return total
    })

    function valueDidChange(id, value) {
      manicureServices.value = manicureServices.value.map(x => (x.id === id ? { ...x, value: value } : x))
      pedicureServices.value = pedicureServices.value.map(x => (x.id === id ? { ...x, value: value } : x))

      personsObject.value.mani = manicureServices.value.map(service => service.value).reduce((acc, val) => acc + val, 0)
      personsObject.value.pedi = pedicureServices.value.map(service => service.value).reduce((acc, val) => acc + val, 0)

      if (personsObject.value.mani > personsObject.value.pedi) {
        persons.value = personsObject.value.mani
      } else {
        persons.value = personsObject.value.pedi
      }
    }

    function search() {
      if (totalManis.value + totalPedis.value === 0) {
        alert("Please select at least one manicure and/or pedicure.")
        return
      }
      if (totalManis.value > 4 || totalPedis.value > 4) {
        alert("Maximum guests per appointment is 4.")
        return
      }

      const manis = manicureServices.value.flatMap(serviceOption =>
        Array(serviceOption.value).fill(serviceOption.id)
      )
      const pedis = pedicureServices.value.flatMap(serviceOption =>
        Array(serviceOption.value).fill(serviceOption.id)
      )
      const serviceIds = [...manis,...pedis].join(",")
      //const serviceIds = manis.concat(pedis).join(",")
      const sameArtist = maniPediCombined.value === "same"

      root.$store.dispatch("appointments/rememberServices", {
        maniOptions: manicureServices.value,
        pediOptions: pedicureServices.value,
        sameArtist
      })

      const routeName = viewer.value.id === props.userId ? "*-services-availability" : "!-services-availability"
      const onlyArtistId = root.$route.query.onlyArtistId as string
      const time = root.$route.query.time as string
      const date = root.$route.query.date as string

      root.$router.push({
        name: routeName,
        query: {
          userId: props.userId,
          sameArtist: sameArtist.toString(),
          persons: persons.value.toString(),
          hands: personsObject.value.mani.toString(),
          feet: personsObject.value.pedi.toString(),
          locationId: currentLocationId.value,
          serviceIds,
          onlyArtistId,
          time,
          date
        }
      })
    }

    return {
      totalManis,
      totalPedis,
      valueDidChange,
      search,
      maniPediCombined,
      locations,
      chooseLocation,
      currentLocationId,
      showLocations,
      nextStep,
      //
      maniDrawerRef,
      pediDrawerRef,
      //
      manicureServices,
      pedicureServices
    }
  }
})
