

























import { computed, createComponent, ref } from "@vue/composition-api"
import ListCancelledAppointments from "@/components/admin/cancelled-appointments-list.vue"
import { endOfDay, startOfDay } from "date-fns"
export default createComponent({
  components: {
    ListCancelledAppointments
  },
  setup() {
    const now = new Date()
    const dateRangeRef = ref([now, new Date(now.getTime() + 60 * 86400 * 1000)])
    const startAtRef = computed(() => startOfDay(dateRangeRef.value[0] as Date))
    const endAtRef = computed(() => endOfDay(dateRangeRef.value[1] as Date))

    // watch(dateRangeRef, async dateRange => {
    //   const now = new Date()
    //   const startAt = dateRange[0] || now
    //   const endAt = dateRange[1] || new Date(now.getTime() + 30 * 86400 * 1000)
    //   if (startAt && endAt) {
    //     transactionsRef.value = await listTransactions(startAt, endAt)
    //   }
    // })

    return {
      dateRangeRef,
      startAtRef,
      endAtRef
    }
  }
})
