

























import { createComponent, ref} from "@vue/composition-api"

export default createComponent({
  props: {
    notes: String
  },

  setup(props, { emit }) {
    const isEditing = ref(false)
    const newNotes = ref(props.notes)

    function handleSaveNotes () {
      emit('change', newNotes.value)
      isEditing.value = false
    }

    function handleEditing () {
      isEditing.value = true
    }

    return {
      isEditing,
      newNotes,
      handleSaveNotes,
      handleEditing
    }
  }
})
