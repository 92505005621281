



















import { computed, createComponent } from "@vue/composition-api"
import DefaultLayout from "@/layouts/default.vue"
import FletchingLayout from "@/layouts/fletching.vue"
import WhiteLayout from "@/layouts/white.vue"

export default createComponent({
  setup(_props, { root }) {
    const hasNoLayout = computed(() => root.$route.meta.noLayout)
    const isInitialLoad = computed(() => root.$store.state.auth.initialLoad)
    const hasFletchingTexture = computed(() => root.$route.meta.fletchingTexture)
    const hasWhiteBackground = computed(() => root.$route.meta.whiteBackground)

    return {
      hasNoLayout,
      isInitialLoad,
      hasFletchingTexture,
      hasWhiteBackground
    }
  },
  components: {
    DefaultLayout,
    FletchingLayout,
    WhiteLayout
  }
})
