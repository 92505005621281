












import { computed, createComponent } from "@vue/composition-api"
import { timeScalarToQuarterHour } from "@/utils/date"

export default createComponent({
  props: {
    artist: {
      type: Object,
      required: true
    },
    time: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    }
  },
  setup(props, { root }) {
    const isAuthenticated = computed(() => root.$store.getters["auth/isAuthenticated"])
    const bookAppointmentRoute = computed(() => (isAuthenticated.value ? "*-services" : "*-sign-up"))
    const qh = computed(() => timeScalarToQuarterHour(props.time))

    return {
      bookAppointmentRoute,
      qh
    }
  }
})
