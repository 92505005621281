import { ActionTree, GetterTree, Module, MutationTree } from "vuex"
import { RootState } from "@/store"
import { createAppointment } from "@/api/appointments"
import { CreateAppointmentInput } from "@/generated/graphql"

export interface State {
  possibleAppointment: CreateAppointmentInput | null
  maniOptions: any
  pediOptions: any
  removalOptions: any
  gelRemovalManiId: any
  gelRemovalPediId: any
  sameArtist: Boolean
  locationId: any
  bookingSource: string
}

const state: State | (() => State) = {
  possibleAppointment: null,
  maniOptions: null,
  pediOptions: null,
  gelRemovalManiId: null,
  gelRemovalPediId: null,
  removalOptions: null,
  sameArtist: true,
  locationId: null,
  bookingSource: 'web'
}

const getters: GetterTree<State, RootState> = {}

const actions: ActionTree<State, RootState> = {
  async createAppointment({ commit }) {
    const { possibleAppointment, removalOptions, gelRemovalManiId, gelRemovalPediId, locationId, bookingSource } = state
    if (possibleAppointment) {
      const modifiedServiceIds = possibleAppointment?.serviceIds.filter(
        el => ![gelRemovalManiId, gelRemovalPediId].includes(el)
      )

      const modifiedPossibleAppoinment: CreateAppointmentInput = {
        userId: possibleAppointment.userId,
        artistIds: possibleAppointment.artistIds,
        serviceIds: modifiedServiceIds,
        options: removalOptions,
        sameArtist: possibleAppointment.sameArtist,
        locationId: locationId,
        time: possibleAppointment.time,
        date: possibleAppointment.date,
        bookingSource: bookingSource
      }

      const response = await createAppointment(modifiedPossibleAppoinment)
      commit("SET_POSSIBLE_APPOINTMENT", null)
      if (response.successful) {
        commit("SET_REMOVAL_OPTIONS", null)
        commit("SET_GEL_REMOVAL_MANI_ID", null)
        commit("SET_GEL_REMOVAL_PEDI_ID", null)
        commit("SET_LOCATION_ID", null)
        commit("SET_MANI_OPTIONS", null)
        commit("SET_PEDI_OPTIONS", null)
        commit("SET_SAME_ARTIST", false)
      }
      return response
    } else {
      return { successful: false, messages: [{ message: "Appointment no longer available." }] }
    }
  },
  async rememberPossibleAppointment({ commit }, possibleAppointment) {
    commit("SET_POSSIBLE_APPOINTMENT", possibleAppointment)
  },
  async rememberServices({ commit }, { maniOptions, pediOptions, sameArtist }) {
    commit("SET_MANI_OPTIONS", maniOptions)
    commit("SET_PEDI_OPTIONS", pediOptions)
    commit("SET_SAME_ARTIST", sameArtist)
  },

  async rememberOptions({ commit }, { removalOptions }) {
    commit("SET_REMOVAL_OPTIONS", removalOptions)
  },

  async rememberLocationId({ commit }, { id }) {
    commit("SET_LOCATION_ID", id)
  },

  async rememberGelRemovalManiServiceId({ commit }, { id }) {
    commit("SET_GEL_REMOVAL_MANI_ID", id)
  },
  async rememberGelRemovalPediServiceId({ commit }, { id }) {
    commit("SET_GEL_REMOVAL_PEDI_ID", id)
  }
}

const mutations: MutationTree<State> = {
  SET_POSSIBLE_APPOINTMENT(state, possibleAppointment) {
    state.possibleAppointment = possibleAppointment
  },
  SET_SAME_ARTIST(state, sameArtist) {
    state.sameArtist = sameArtist
  },
  SET_MANI_OPTIONS(state, maniOptions) {
    state.maniOptions = maniOptions
  },
  SET_PEDI_OPTIONS(state, pediOptions) {
    state.pediOptions = pediOptions
  },
  SET_REMOVAL_OPTIONS(state, removalOptions) {
    state.removalOptions = removalOptions
  },
  SET_GEL_REMOVAL_MANI_ID(state, id) {
    state.gelRemovalManiId = id
  },
  SET_GEL_REMOVAL_PEDI_ID(state, id) {
    state.gelRemovalPediId = id
  },
  SET_LOCATION_ID(state, id) {
    state.locationId = id
  }
}

const module: Module<State, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
export default module
