import { query, mutate } from "@/api"
import {
  Appointment,
  CancelAppointmentInput,
  CancelAppointmentPayload,
  CheckInAppointmentInput,
  CheckInAppointmentPayload,
  CreateAppointmentInput,
  Filters,
  CreateAppointmentPayload,
  PossibleAppointment
} from "@/generated/graphql"
import gql from "graphql-tag"

export async function cancelAppointment(input: CancelAppointmentInput): Promise<CancelAppointmentPayload> {
  const response = await mutate({
    mutation: gql`
      mutation CancelAppointment($input: CancelAppointmentInput!) {
        payload: cancelAppointment(input: $input) {
          successful
          messages {
            code
            field
            message
          }
          result {
            id
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function checkInAppointment(input: CheckInAppointmentInput): Promise<CheckInAppointmentPayload> {
  const response = await mutate({
    mutation: gql`
      mutation CheckInAppointment($input: CheckInAppointmentInput!) {
        payload: checkInAppointment(input: $input) {
          successful
          messages {
            code
            field
            message
          }
          result {
            id
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function createAppointment(input: CreateAppointmentInput): Promise<CreateAppointmentPayload> {
  const response = await mutate({
    mutation: gql`
      mutation CreateAppointment($input: CreateAppointmentInput!) {
        payload: createAppointment(input: $input) {
          successful
          messages {
            code
            field
            message
          }
          result {
            id
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function getAppointment(id: string): Promise<Appointment | null> {
  const response = await query({
    query: gql`
      query GetAppointment($id: ID!) {
        appointment(id: $id) {
          id
          location {
            id
            name
            address
            description
          }
          artists {
            id
            photoUrl
            name
          }
          cancelledAt
          checkedInAt
          date
          services {
            title
            type
          }
          time
          user {
            id
            displayName
            fullName
            photoUrl
            pronoun
          }
          appointmentServices {
            id
            time
            startAt
            artist {
              id
              photoUrl
              name
            }
            services {
              title
              type
              duration
              slug
            }
          }
        }
      }
    `,
    variables: { id }
  })
  return response.data.appointment
}

export async function listAppointments(filters): Promise<Array<Appointment>> {
  const response = await query({
    query: gql`
      query ListAppointments(
        $artistIds: [ID!]
        $userIds: [ID!]
        $startAt: DateTime
        $endAt: DateTime
        $locationId: ID
      ) {
        appointments(
          artistIds: $artistIds
          userIds: $userIds
          startAt: $startAt
          endAt: $endAt
          locationId: $locationId
        ) {
          id
          bookingSource
          insertedAt
          artists {
            id
            photoUrl
            name
          }
          cancelledAt
          checkedInAt
          date
          appointmentServices {
            id
            time
            startAt
            artist {
              id
              photoUrl
              name
            }
            services {
              duration
              title
              type
              slug
            }
          }
          services {
            duration
            title
            type
            slug
          }
          time
          user {
            id
            isMember
            fullName
            photoUrl
            pronoun
            servicePackCount
          }
          location {
            id
            name
            address
            phoneNumber
            description
          }
        }
      }
    `,
    variables: filters
  })
  return response.data.appointments
}

export async function listCancelledAppointments(filters): Promise<Array<Appointment>> {
  const response = await query({
    query: gql`
      query ListCancelledAppointments(
        $artistIds: [ID!]
        $userIds: [ID!]
        $startAt: DateTime
        $endAt: DateTime
        $locationId: ID
      ) {
        cancelledAppointments(
          artistIds: $artistIds
          userIds: $userIds
          startAt: $startAt
          endAt: $endAt
          locationId: $locationId
        ) {
          id
          cancelledAt
          checkedInAt
          date
          appointmentServices {
            id
            time
            startAt
            artist {
              id
              photoUrl
              name
            }
            services {
              duration
              title
              type
              slug
            }
          }
          services {
            duration
            title
            type
            slug
          }
          time
          user {
            id
            isMember
            fullName
            photoUrl
            pronoun
            servicePackCount
          }
          location {
            id
            name
            address
            phoneNumber
            description
          }
        }
      }
    `,
    variables: filters
  })
  return response.data.cancelledAppointments
}

export async function listPossibleAppointments(
  serviceIds: Array<string>,
  sameArtist: boolean,
  locationId: String,
  artistIds: Array<string> | null = null,
  filters: Filters | null = null,
  options: Array<any> | null = null
): Promise<Array<PossibleAppointment>> {
  const response = await query({
    query: gql`
      query ListPossibleAppointments(
        $serviceIds: [ID!]!
        $sameArtist: Boolean!
        $locationId: ID!
        $artistIds: [ID!]
        $filters: Filters
        $options: [ID]
      ) {
        possibleAppointments(
          serviceIds: $serviceIds
          sameArtist: $sameArtist
          locationId: $locationId
          artistIds: $artistIds
          filters: $filters
          options: $options
        ) {
          date
          time
          artists {
            id
            photoUrl
            name
          }
          services {
            id
            title
            type
          }
        }
      }
    `,
    variables: { serviceIds, sameArtist, locationId, artistIds, filters, options }
  })
  return response.data.possibleAppointments
}
