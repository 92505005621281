import { mutate, query } from "@/api"
import {
  CreateArtistInput,
  CreateArtistPayload,
  UpdateArtistInput,
  UpdateArtistPayload,
  Artist
} from "@/generated/graphql"
import gql from "graphql-tag"

export const fragments = {
  artistPublicFields: gql`
    fragment artistPublicFields on Artist {
      bio
      id
      music
      name
      photoUrl
      polish
      quote
    }
  `
}
export async function createArtist(input: CreateArtistInput): Promise<CreateArtistPayload> {
  const response = await mutate({
    mutation: gql`
      mutation CreateArtist($input: CreateArtistInput!) {
        payload: createArtist(input: $input) {
          successful
          messages {
            code
            field
            message
          }
          result {
            id
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function getAdminArtist(id: String): Promise<Artist | null> {
  const response = await query({
    query: gql`
      query GetAdminArtist($id: ID!) {
        artist(id: $id) {
          ...artistPublicFields
          disabledAt
          user {
            id
            fullName
          }
          workSchedules {
            id
            date
            startTime
            endTime
            location {
              id
              name
              address
              phoneNumber
            }
          }
        }
      }
      ${fragments.artistPublicFields}
    `,
    variables: { id }
  })
  return response.data.artist
}

export async function getArtistById(id: String): Promise<Artist | null> {
  const response = await query({
    query: gql`
      query UserArtist($id: ID!) {
        userArtist(id: $id) {
          ...artistPublicFields
          disabledAt
          user {
            id
            fullName
          }
          workSchedules {
            id
            date
            startTime
            endTime
            location {
              id
              name
              address
              phoneNumber
            }
          }
        }
      }
      ${fragments.artistPublicFields}
    `,
    variables: { id }
  })

  return response.data.userArtist
}

export async function getPublicArtist(id: String): Promise<Artist | null> {
  const response = await query({
    query: gql`
      query GetAdminArtist($id: ID!) {
        artist(id: $id) {
          ...artistPublicFields
          workSchedules {
            id
            date
            startTime
            endTime
            location {
              id
              name
              address
              description
              phoneNumber
            }
          }
        }
      }
      ${fragments.artistPublicFields}
    `,
    variables: { id }
  })
  return response.data.artist
}
// opts = { includeDisabled: true }
export async function listArtists(opts = {}): Promise<Array<Artist>> {
  const response = await query({
    query: gql`
      query ListArtists($includeDisabled: Boolean) {
        artists(includeDisabled: $includeDisabled) {
          ...artistPublicFields
          disabledAt
        }
      }
      ${fragments.artistPublicFields}
    `,
    variables: opts
  })
  return response.data.artists
}

export async function updateArtist(input: UpdateArtistInput): Promise<UpdateArtistPayload> {
  const response = await mutate({
    mutation: gql`
      mutation UpdateArtist($input: UpdateArtistInput!) {
        payload: updateArtist(input: $input) {
          successful
          messages {
            code
            field
            message
          }
          result {
            id
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}
