declare var define: any
/*! ics.js Wed Sept 14 2017 */
var saveAs =
  saveAs ||
  (function(e) {
    "use strict"
    if (typeof e === "undefined" || (typeof navigator !== "undefined" && /MSIE [1-9]\./.test(navigator.userAgent))) {
      return
    }
    var t = e.document,
      n = function() {
        return e.URL || e.webkitURL || e
      },
      r = t.createElementNS("http://www.w3.org/1999/xhtml", "a"),
      o = "download" in r,
      a = function(e) {
        var t = new MouseEvent("click")
        e.dispatchEvent(t)
      },
      i = /constructor/i.test(e.HTMLElement) || e.safari,
      f = /CriOS\/[\d]+/.test(navigator.userAgent),
      u = function(t) {
        ;(e.setImmediate || e.setTimeout)(function() {
          throw t
        }, 0)
      },
      s = "application/octet-stream",
      d = 1e3 * 40,
      c = function(e) {
        var t = function() {
          if (typeof e === "string") {
            n().revokeObjectURL(e)
          } else {
            e.remove()
          }
        }
        setTimeout(t, d)
      },
      l = function(e, t, n = false) {
        t = [].concat(t)
        var r = t.length
        while (r--) {
          var o = e["on" + t[r]]
          if (typeof o === "function") {
            try {
              o.call(e, n || e)
            } catch (a) {
              u(a)
            }
          }
        }
      },
      p = function(e) {
        if (/^\s*(?:text\/\S*|application\/xml|\S*\/\S*\+xml)\s*;.*charset\s*=\s*utf-8/i.test(e.type)) {
          return new Blob([String.fromCharCode(65279), e], {
            type: e.type
          })
        }
        return e
      },
      v = function(t, u, d) {
        if (!d) {
          t = p(t)
        }
        var v = this,
          w = t.type,
          m = w === s,
          y,
          h = function() {
            l(v, "writestart progress write writeend".split(" "))
          },
          S = function() {
            if ((f || (m && i)) && e.FileReader) {
              var r: any = new FileReader()
              r.onloadend = function() {
                var t = f ? r.result : r.result.replace(/^data:[^;]*;/, "data:attachment/file;")
                var n = e.open(t, "_blank")
                if (!n) e.location.href = t
                t = undefined
                v.readyState = v.DONE
                h()
              }
              r.readAsDataURL(t)
              v.readyState = v.INIT
              return
            }
            if (!y) {
              y = n().createObjectURL(t)
            }
            if (m) {
              e.location.href = y
            } else {
              var o = e.open(y, "_blank")
              if (!o) {
                e.location.href = y
              }
            }
            v.readyState = v.DONE
            h()
            c(y)
          }
        v.readyState = v.INIT
        if (o) {
          y = n().createObjectURL(t)
          setTimeout(function() {
            r.href = y
            r.download = u
            a(r)
            h()
            c(y)
            v.readyState = v.DONE
          })
          return
        }
        S()
      },
      w = v.prototype,
      m = function(e, t, n) {
        return new v(e, t || e.name || "download", n)
      }
    if (typeof navigator !== "undefined" && navigator.msSaveOrOpenBlob) {
      return function(e, t, n) {
        t = t || e.name || "download"
        if (!n) {
          e = p(e)
        }
        return navigator.msSaveOrOpenBlob(e, t)
      }
    }
    w.abort = function() {}
    w.readyState = w.INIT = 0
    w.WRITING = 1
    w.DONE = 2
    w.error = w.onwritestart = w.onprogress = w.onwrite = w.onabort = w.onerror = w.onwriteend = null
    return m
  })((typeof self !== "undefined" && self) || (typeof window !== "undefined" && window) || this.content)
if (typeof module !== "undefined" && module.exports) {
  module.exports.saveAs = saveAs
} else if (typeof define !== "undefined" && define !== null && define.amd !== null) {
  define("FileSaver.js", function() {
    return saveAs
  })
}

/* global saveAs, Blob, BlobBuilder, console */
/* exported ics */

var ics = function(uidDomain = "default", prodId = "Calendar", extraHeaders = [], eventTimezoneId = false) {
  if (navigator.userAgent.indexOf("MSIE") > -1 && navigator.userAgent.indexOf("MSIE 10") == -1) {
    console.log("Unsupported Browser")
    return
  }

  if (typeof uidDomain === "undefined") {
    uidDomain = "default"
  }
  if (typeof prodId === "undefined") {
    prodId = "Calendar"
  }

  var SEPARATOR = "\r\n"
  var calendarEvents = []
  var calendarStart = ["BEGIN:VCALENDAR", "PRODID:" + prodId, "VERSION:2.0"].concat(extraHeaders).join(SEPARATOR)
  var calendarEnd = SEPARATOR + "END:VCALENDAR"

  return {
    /**
     * Returns events array
     * @return {array} Events
     */
    events: function() {
      return calendarEvents
    },

    /**
     * Returns calendar
     * @return {string} Calendar in iCalendar format
     */
    calendar: function() {
      return calendarStart + SEPARATOR + calendarEvents.join(SEPARATOR) + calendarEnd
    },

    /**
     * Add event to the calendar
     * @param  {string} subject     Subject/Title of event
     * @param  {string} description Description of event
     * @param  {string} location    Location of event
     * @param  {string} begin       Beginning date of event
     * @param  {string} stop        Ending date of event
     */
    // 'addEvent': function (subject, description, location, begin, stop, rrule) {
    addEvent: function(subject, description, location, begin, stop) {
      // I'm not in the mood to make these optional... So they are all required
      if (
        typeof subject === "undefined" ||
        typeof description === "undefined" ||
        typeof location === "undefined" ||
        typeof begin === "undefined" ||
        typeof stop === "undefined"
      ) {
        return false
      }

      //TODO add time and time zone? use moment to format?
      var start_date = new Date(begin)
      var end_date = new Date(stop)
      var now_date = new Date()

      var start_year = ("0000" + start_date.getFullYear().toString()).slice(-4)
      var start_month = ("00" + (start_date.getMonth() + 1).toString()).slice(-2)
      var start_day = ("00" + start_date.getDate().toString()).slice(-2)
      var start_hours = ("00" + start_date.getHours().toString()).slice(-2)
      var start_minutes = ("00" + start_date.getMinutes().toString()).slice(-2)
      var start_seconds = ("00" + start_date.getSeconds().toString()).slice(-2)

      var end_year = ("0000" + end_date.getFullYear().toString()).slice(-4)
      var end_month = ("00" + (end_date.getMonth() + 1).toString()).slice(-2)
      var end_day = ("00" + end_date.getDate().toString()).slice(-2)
      var end_hours = ("00" + end_date.getHours().toString()).slice(-2)
      var end_minutes = ("00" + end_date.getMinutes().toString()).slice(-2)
      var end_seconds = ("00" + end_date.getSeconds().toString()).slice(-2)

      var now_year = ("0000" + now_date.getUTCFullYear().toString()).slice(-4)
      var now_month = ("00" + (now_date.getUTCMonth() + 1).toString()).slice(-2)
      var now_day = ("00" + now_date.getUTCDate().toString()).slice(-2)
      var now_hours = ("00" + now_date.getUTCHours().toString()).slice(-2)
      var now_minutes = ("00" + now_date.getUTCMinutes().toString()).slice(-2)
      var now_seconds = ("00" + now_date.getUTCSeconds().toString()).slice(-2)

      // Since some calendars don't add 0 second events, we need to remove time if there is none...
      var start_time = ""
      if (start_hours !== "00" || start_minutes !== "00" || start_seconds !== "00") {
        start_time = "T" + start_hours + start_minutes + start_seconds
      }
      var end_time = ""
      if (end_hours !== "00" || end_minutes !== "00" || end_seconds !== "00") {
        end_time = "T" + end_hours + end_minutes + end_seconds
      }
      var now_time = "T" + now_hours + now_minutes + now_seconds

      var start = start_year + start_month + start_day + start_time
      var end = end_year + end_month + end_day + end_time
      var now = now_year + now_month + now_day + now_time + "Z"

      var calendarEvent = [
        "BEGIN:VEVENT",
        "UID:" + calendarEvents.length + "@" + uidDomain,
        "CLASS:PUBLIC",
        "DESCRIPTION:" + description
      ]

      calendarEvent.push("DTSTAMP:" + now)

      if (start_time !== "") {
        if (eventTimezoneId) {
          calendarEvent.push("DTSTART;TZID=" + eventTimezoneId + ":" + start)
        } else {
          calendarEvent.push("DTSTART:" + start)
        }
      } else {
        calendarEvent.push("DTSTART;VALUE=DATE:" + start)
      }

      // If start and end refer to the same day without time,
      // it's a one day event, and no DTEND is needed.
      // https://stackoverflow.com/a/30249034
      if (end_time !== "") {
        if (eventTimezoneId) {
          calendarEvent.push("DTEND;TZID=" + eventTimezoneId + ":" + end)
        } else {
          calendarEvent.push("DTEND:" + end)
        }
      } else if (end !== start) {
        calendarEvent.push("DTEND;VALUE=DATE:" + end)
      }

      calendarEvent.push(
        "LOCATION:" + location,
        "SUMMARY;LANGUAGE=en-us:" + subject,
        "TRANSP:TRANSPARENT",
        "END:VEVENT"
      )

      calendarEvents.push(calendarEvent.join(SEPARATOR))
      return calendarEvent
    },

    /**
     * Download calendar using the saveAs function from filesave.js
     * @param  {string} filename Filename
     * @param  {string} ext      Extention
     */
    download: function(filename, ext) {
      if (calendarEvents.length < 1) {
        return false
      }

      ext = typeof ext !== "undefined" ? ext : ".ics"
      filename = typeof filename !== "undefined" ? filename : "calendar"
      var calendar = calendarStart + SEPARATOR + calendarEvents.join(SEPARATOR) + calendarEnd

      var blob
      if (navigator.userAgent.indexOf("MSIE 10") === -1) {
        // chrome or firefox
        blob = new Blob([calendar], { type: "text/calendar;charset=" + document.characterSet })
      } else {
        // ie
        var bb = new MSBlobBuilder()
        bb.append(calendar)
        blob = bb.getBlob("text/x-vCalendar;charset=" + document.characterSet)
      }
      saveAs(blob, filename + ext)
      return calendar
    },

    /**
     * Build and return the ical contents
     */
    build: function() {
      if (calendarEvents.length < 1) {
        return false
      }

      var calendar = calendarStart + SEPARATOR + calendarEvents.join(SEPARATOR) + calendarEnd

      return calendar
    }
  }
}

export default ics
