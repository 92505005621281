
















































































import { createComponent, reactive, toRefs, watch } from "@vue/composition-api"
import { changePassword, getUser } from "@/api/users"
import { Maybe, User } from "@/generated/graphql"
import { MessageBoxInputData } from "element-ui/types/message-box"
import { useCloudinary } from "@/use/use-cloudinary"

export default createComponent({
  props: {
    id: String
  },
  setup({ id }, { root }) {
    const state = reactive({
      form: {
        id: "",
        displayName: "",
        fullName: "",
        email: "",
        mobilePhone: "",
        pronoun: "",
        newsletter: false,
        managerRole: false,
        disable: false
      },
      errors: {
        displayName: "",
        fullName: "",
        email: "",
        mobilePhone: "",
        pronoun: "",
        newsletter: ""
      },
      submitting: false
    })

    watch(async () => {
      const user: Maybe<User> = await getUser(id)
      state.form.displayName = user.displayName
      state.form.email = user.email
      state.form.id = user.id
      state.form.fullName = user.fullName
      state.form.mobilePhone = user.mobilePhone
      state.form.pronoun = user.pronoun
      state.form.newsletter = user.isNewsletterSubscriber
      state.form.managerRole = user.roles.includes("manager")
      state.form.disable = !!user.disabledAt
    })

    async function updateUser() {
      state.submitting = true
      state.errors.displayName = ""
      state.errors.fullName = ""
      state.errors.email = ""
      state.errors.mobilePhone = ""
      state.errors.pronoun = ""
      state.errors.newsletter = ""

      const { successful, result, messages } = await root.$store.dispatch("users/updateUser", state.form)
      state.submitting = false
      if (!successful) {
        messages.forEach(({ field, message }) => {
          state.errors[field] = message
        })
      } else {
        root.$router.push({ name: "!-users-show", params: { id: result.id } })
      }
    }

    async function showChangePassword() {
      try {
        const { value } = (await root.$prompt("Enter a new password.", "Change password", {
          confirmButtonText: "Change password",
          showCancelButton: false,
          inputType: "password",
          inputValidator: value => value && value.length >= 8,
          inputErrorMessage: "Password is too short."
        })) as MessageBoxInputData

        if (value && value.length >= 8) {
          const { successful, messages } = await changePassword({ id, password: value })
          if (successful) {
            root.$message({ type: "success", message: "Password changed." })
          } else {
            root.$message({ type: "error", message: messages[0].message })
          }
        }
      } catch {
        // ignore
      }
    }

    // cloudinary uploads
    const config = root.$store.state.auth.configuration
    const { uploadWidget } = useCloudinary(config.cloudinaryCloudName, config.cloudinaryUploadPreset, async info => {
      const { successful, messages } = await root.$store.dispatch("users/updateUser", {
        id,
        cloudinaryPublicId: info.public_id
      })
      if (successful) {
        root.$router.push({ name: "!-users-show", params: { id } })
        return
      }
      alert(messages[0].message)
    })
    function openUploader() {
      uploadWidget.open()
    }

    return {
      showChangePassword,
      updateUser,
      openUploader,
      ...toRefs(state)
    }
  }
})
