








import LayoutHeader from "./header.vue"
import LayoutFooter from "./footer.vue"

import { computed, createComponent } from "@vue/composition-api"

export default createComponent({
  setup(_props, { root }) {
    const isClientFacing = computed(() => root.$route.matched.some(r => r.meta.isClientFacing))

    return {
      isClientFacing
    }
  },
  components: {
    LayoutHeader,
    LayoutFooter
  }
})
