















































import { createComponent } from "@vue/composition-api"

export default createComponent({
  props: {
    artist: {
      type: Object,
      required: false
    }
  },
  setup() {
    // do something
  }
})
