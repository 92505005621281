









































import { computed, createComponent, ref } from "@vue/composition-api"
import { format, parseISO } from "date-fns"
import { Appointment } from "@/generated/graphql"
import { MessageBoxInputData } from "element-ui/types/message-box"
import { cancelAppointment } from "@/api/appointments"

export default createComponent({
  props: {
    appointment: {
      type: Object as () => Appointment,
      required: true
    },

    hasCancelButton: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { root }) {
    const formattedTime = computed(() => {
      const date = parseISO(props.appointment.date + "T" + props.appointment.time)
      return format(date, "MMM d, EEEE h:mm bbbb")
    })

    const fullName = (service) => {
      if (service.type === "Manicure" || service.type === "Pedicure") {
        return `${service.title} ${service.type}`
      } else {
        const packName = service.slug.slice('gel_polish_removal_'.length)
        return `${service.title} ${packName === 'mani' ? 'Fingers' : 'Toes' }`
      }
    }
    const locationInfo = computed(() => {
      const location = props.appointment.location
      return `${location.address}, ${location.name}`
    })

    // works only with 1 person
    const groupServiceNamesByOnePerson = (services) => {
      const grouped = []
      const hasRemoval = services.filter((service) => service.type === 'Other')
      const groupedNames = []
      if (hasRemoval.length > 0) {
        for (let i = 0; i < services.length; i++) {
          if (services[i].type === 'Manicure') {
            for (let j = 0; j < services.length; j++) {
              if (services[j].slug === 'gel_polish_removal_mani') {
                grouped.push([services[j], services[i]])
                groupedNames.push(services[j].slug)
                groupedNames.push(services[i].slug)
              }
            }
          }

          if (services[i].type === 'Pedicure') {
            for (let j = 0; j < services.length; j++) {
              if (services[j].slug === 'gel_polish_removal_pedi') {
                grouped.push([services[j], services[i]])
                groupedNames.push(services[j].slug)
                groupedNames.push(services[i].slug)
              }
            }
          }
        }
      } else {
        return services
      }

      // console.log(services.filter(k => !groupedNames.includes(k.slug)))
      return [ ...grouped.flat(), ...services.filter(k => !groupedNames.includes(k.slug))]
    }

    const formatTime = (time) => {
      const date = parseISO(props.appointment.date +  "T" + time)
      return format(date, "h:mm bbbb")
    }

    const cancelled = ref(false)


    async function handleCancel() {
      try {
        const { value } = (await root.$prompt(
          "Are you sure you want to cancel your appointment? This cannot be undone. If your cancellation is " +
            "within 24 hours of your appointment time, your pack credit will not be returned to your account. " +
            "If you cancel prior to 24 hours of your appointment, you will find the credit in your Archer account. " + 
            "Please contact us if you will not have an opportunity to use your credit." +
            "Type CANCEL in all caps.",
          "Cancel Appointment",
          {
            confirmButtonText: "Cancel appointment",
            showCancelButton: false,
            inputType: "text",
            inputValidator: value => value === "CANCEL",
            inputErrorMessage: "Type CANCEL in all caps."
          }
        )) as MessageBoxInputData

        if (value === "CANCEL") {
          const { successful } = await cancelAppointment({ id: props.appointment.id })
          if (successful) {
            cancelled.value = true
          }
        }
      } catch {
        // ignore
      }
    }

    return {
      formattedTime,
      cancelled,
      groupServiceNamesByOnePerson,
      handleCancel,
      formatTime,
      fullName,
      locationInfo
    }
  }
})
