










import { computed, createComponent } from "@vue/composition-api"

const cssClassMap = {
  classic: "classic",
  recovery: "recovery",
  gel: "gel",
  express: "express",
  kids: "kids",
}

export default createComponent({
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    const cssClass = computed(() => cssClassMap[props.title.toLowerCase()])
    let allowClick = true

    function clicked() {
      if (props.value === 0) {
        if (allowClick) {
          emit("input", props.id, 1)
          allowClick = false
        } else {
          allowClick = true
        }
      }
    }
    function countChanged(newValue) {
      emit("input", props.id, newValue)
    }
    return { cssClass, clicked, countChanged }
  }
})
