import { computed } from "@vue/composition-api"

function normalize(string) {
  return string
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
}

function removeNonalphanumerics(string) {
  return string.replace(/[^a-z0-9]/g, "")
}

// Create searchable entities.
//
// Example:
//
//  const users = ref([user_object_1, user_object_2, ...])
//  const search = ref("term")
//  const { searchResults } = useSearchable(users, ["fullName", "email", "mobilePhone"], search)
//
export function useSearchable(entitiesRef, searchFields, queryRef) {
  const searchable = computed(() =>
    entitiesRef.value
      .map(entity => {
        const search = normalize(searchFields.map(field => entity[field]).join(" "))
        return { entity, search }
      })
      .sort((a, b) => a.search.localeCompare(b.search))
  )

  const searchResults = computed(() => {
    const term = removeNonalphanumerics(normalize(queryRef.value))
    const regex = new RegExp(term.split("").join(".*?"))
    return searchable.value.filter(obj => obj.search.match(regex)).map(obj => obj.entity)
  })

  return { searchResults }
}
