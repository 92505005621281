import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"
import { computed } from "@vue/composition-api"
import { adminRoutes } from "./admin"
import { clientRoutes } from "./client"
import VueAnalytics from "vue-analytics"

Vue.use(VueRouter)

export function makeRouter(store) {
  const router = new VueRouter({
    mode: "history",
    routes: [...clientRoutes, ...adminRoutes, { path: "*", redirect: "/" }] as Array<RouteConfig>,
    scrollBehavior() {
      return { x: 0, y: 0 }
    }
  })

  const isAuthenticated = computed(() => store.getters["auth/isAuthenticated"])
  const isManager = computed(() => store.getters["auth/isManager"])

  router.beforeEach(async (to, _from, next) => {
    if (to.matched.some(record => record.meta.signOut)) {
      await store.dispatch("auth/signOut") // never returns
      return
    }
    if (to.matched.some(record => record.meta.requiresViewer && !isAuthenticated.value)) {
      next({ name: "*-sign-in", query: { redirect: `${to.fullPath.slice(1)}` } })
      return
    }
    if (to.matched.some(record => record.meta.requiresManager) && !isManager.value && isAuthenticated.value) {
      alert("You do not have admin privileges")
      next({ name: "*-homepage" })
      return
    }
    if (to.matched.some(record => record.meta.requiresManager) && !isManager.value) {
      next({ name: "*-sign-in", query: { redirect: `${to.fullPath.slice(1)}` } })
      return
    }
    next()
  })

  Vue.use(VueAnalytics, {
    id: "UA-160644203-1",
    router
  })

  return router
}
