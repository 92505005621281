





























import { computed, createComponent } from "@vue/composition-api"
import { addDays, differenceInCalendarDays, format, isEqual, startOfToday, startOfWeek, endOfWeek } from "date-fns"

function formatMonthDay(date) {
  return format(date, "MMM d")
}

export default createComponent({
  props: {
    value: {
      type: Date,
      required: true
    }
  },
  setup(props, { emit }) {
    const today = startOfToday()
    const latestDay = addDays(today, 12 * 7)

    const leftMostDay = computed(() => {
      return days.value[0].date
    })

    const weekStart = computed(() => startOfWeek(props.value as any))
    const weekEnd = computed(() => endOfWeek(props.value as any))

    const disablePrevious = computed(() => {
      const toReturn = leftMostDay.value < today
      return toReturn
    })
    const disableNext = computed(() => addDays(leftMostDay.value as any, 7) > latestDay)

    const weekLabel = computed(() => {
      return `${formatMonthDay(weekStart.value)} - ${formatMonthDay(weekEnd.value)}`
    })

    const days = computed(() => {
      const cols = []

      for (let i = 0; i < 7; i++) {
        const date = addDays(weekStart.value as any, i)

        const selected = isEqual(props.value as any, date)
        const disabled = differenceInCalendarDays(today as any, date) > 0

        cols.push({
          date,
          selected: selected,
          dayOfWeek: format(date, "EEE"),
          dayOfMonth: format(date, "d"),
          disabled: disabled
        })
      }
      return cols
    })

    function jump(count) {
      const isForward = count > 0
      let daysChange = count
      if (days.value) {
        let selectedIndex = 0
        // tried to use .findIndex() but had errors ..
        for (let i = 0; i < days.value.length; i++) {
          if (days.value[i].selected) {
            selectedIndex = i
            break
          }
        }
        daysChange = isForward ? days.value.length - selectedIndex : -1 * (selectedIndex + 7)
      }
      const newDate = addDays(props.value as any, daysChange)
      emit("input", newDate < today ? today : newDate)
    }

    function select(date) {
      emit("input", date)
    }

    return { select, days, disablePrevious, disableNext, jump, weekLabel }
  }
})
