




























































import { createComponent, reactive, ref, toRefs } from "@vue/composition-api"
import { listUsersForArtist } from "@/api/users"

export default createComponent({
  setup(_props, { root }) {
    const users = ref([])
    const state = reactive({
      form: {
        bio: "",
        music: "",
        name: "",
        polish: "",
        quote: "",
        userId: ""
      },
      errors: {
        bio: "",
        music: "",
        name: "",
        polish: "",
        quote: "",
        userId: ""
      },
      submitting: false
    })

    async function fetchUsersForArtist() {
      const results = await listUsersForArtist()
      users.value = results.sort((a, b) => a.fullName.localeCompare(b.fullName))
    }
    fetchUsersForArtist()

    async function createArtist() {
      state.submitting = true
      state.errors.bio = ""
      state.errors.music = ""
      state.errors.name = ""
      state.errors.polish = ""
      state.errors.quote = ""
      state.errors.userId = ""

      const { successful, result, messages } = await root.$store.dispatch("artists/createArtist", state.form)
      state.submitting = false
      if (!successful) {
        messages.forEach(({ field, message }) => {
          state.errors[field] = message
        })
      } else {
        root.$router.push({ name: "!-artists-show", params: { id: result.id } })
      }
    }

    return {
      users,
      createArtist,
      ...toRefs(state)
    }
  }
})
