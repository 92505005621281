


































import { createComponent, ref } from "@vue/composition-api"
import { resetPassword } from "@/api/users"

export default createComponent({
  setup() {
    const emailRef = ref("")
    const isSubmittingRef = ref(false)
    const sentRef = ref(false)

    async function onSubmit() {
      isSubmittingRef.value = true
      await resetPassword({ email: emailRef.value })
      sentRef.value = true
    }

    return {
      emailRef,
      isSubmittingRef,
      sentRef,
      onSubmit
    }
  }
})
