import { ActionTree, GetterTree, Module, MutationTree } from "vuex"
import { RootState } from "@/store"

import { RegisterUserInput } from "@/generated/graphql"

export interface State {
  user: RegisterUserInput
}

const state: State | (() => State) = {
  user: null
}

const getters: GetterTree<State, RootState> = {
  hasUser() {
    return state.user !== null
  },
  getUser() {
    return state.user
  }
}

const actions: ActionTree<State, RootState> = {
  updateUser({ commit }, input) {
    commit("SET_USER", input)
  }
}

const mutations: MutationTree<State> = {
  SET_USER(state: State, payload: RegisterUserInput) {
    state.user = payload
  }
}

const module: Module<State, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
export default module
