


























import { computed, createComponent } from "@vue/composition-api"
import ListAppointments from "@/components/admin/appointments-list.vue"
import { DateTime as dt } from "luxon"

export default createComponent({
  components: {
    ListAppointments
  },
  setup(_props, { root }) {
    const isAdmin = root.$store.getters["auth/isAdmin"]
    const isArtist = computed(() => root.$store.getters["auth/isArtist"])
    const startAtRef = computed(() => dt.local().toJSDate())
    const globalLocationId = computed(() => root.$store.getters["admins/currentLocationId"])

    return {
      isAdmin,
      isArtist,
      startAtRef,
      globalLocationId
    }
  },
  methods: {
    databaseClick: function databaseClick() {
      window.location.href = "/database"
    }
  }
})
