declare const cloudinary

// Use Cloudinary upload widget. Invokes successCallback(info) on success, `public_id` is important, in particular.
//
export function useCloudinary(cloudName, uploadPreset, successCallback) {
  function eventHandler(error, { event, info }) {
    if (error) {
      alert(error)
      return
    }
    if (event === "success") {
      successCallback(info)
    }
  }

  const uploadWidget = cloudinary.createUploadWidget(
    {
      cloudName,
      minImageWidth: 400,
      minImageHeight: 400,
      multiple: false,
      resourceType: "image",
      showUploadMoreButton: false,
      sources: ["local", "camera"],
      uploadPreset
    },
    eventHandler
  )
  return { uploadWidget }
}
