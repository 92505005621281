








import { computed, createComponent, ref, watch } from "@vue/composition-api"
import { quarterHourToLabel } from "@/utils/date"

// calculate 12:00am (0) to 11:45pm (95)
const quarterHours = []
for (let i = 0; i < 96; i++) {
  quarterHours.push(quarterHourToLabel(i))
}

export default createComponent({
  props: {
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    value: {
      type: Number, // 0-95, -1 =unselected
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: false
    }

  },
  setup(props, { emit }) {
    const selected = ref(quarterHours[props.value] || "")
    const options = computed(() => quarterHours.slice(props.min, props.max + 1))

    function change(item) {
      emit("input", quarterHours.indexOf(item))
    }

    // this watchs changes in max / min and adjusts the selected value (iff there is a selection0)
    watch(() => {
      const selectedValue = quarterHours.indexOf(selected.value)
      if (selectedValue < props.min && selected.value !== "") {
        // selected value exists and is less than min, set to min
        selected.value = quarterHours[props.min]
        emit("input", props.min)
      } else if (selectedValue > props.max) {
        // selected value is greater than max, set to max
        selected.value = quarterHours[props.max]
        emit("input", props.max)
      }
    })
    return { options, change, selected }
  }
})
