












import { createComponent, reactive, ref, toRefs } from "@vue/composition-api"
import { Card, createToken } from "vue-stripe-elements-plus"
import { addPaymentMethod } from "@/api/purchases"

let style = {
  base: {
    backgroundColor: "white",
    color: "#000000",
    fontWeight: 300,
    fontFamily: "proxima-nova, sans-serif",
    fontSize: "18px",
    "::placeholder": {
      color: "#999"
    }
  },
  invalid: {
    // All of the error styles go inside of here.
  }
}
export default createComponent({
  components: { Card },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  setup(props, { emit, root }) {
    const state = reactive({
      complete: false,
      stripeOptions: {
        style
        // see https://stripe.com/docs/stripe.js#element-options for details
      },
      apiKey: root.$store.state.auth.configuration.stripePublicApiKey
    })
    const submitting = ref(false)

    async function addCreditCard() {
      // createToken returns a Promise which resolves in a result object with
      // either a token or an error key.
      // See https://stripe.com/docs/api#tokens for the token object.
      // See https://stripe.com/docs/api#errors for the error object.
      // More general https://stripe.com/docs/stripe.js#stripe-create-token.
      const {token, error} = await createToken()
      if (error) {
        root.$alert(error.message, "Bad credit card.", {
          confirmButtonText: "OK"
        })
        return
      } else if (!token) {
        root.$alert("Unknown", "Bad credit card.", {
          confirmButtonText: "OK"
        })
      }
      submitting.value = true
      const { successful, result, messages } = await addPaymentMethod({
        userId: props.userId,
        tokenJson: JSON.stringify(token)
      })
      submitting.value = false

      if (successful) {
        emit("added", result.paymentMethod)
      } else {
        root.$alert(messages[0].message, "Bad credit card.", {
          confirmButtonText: "OK"
        })
      }
    }

    return {
      addCreditCard,
      submitting,
      ...toRefs(state)
    }
  }
})
