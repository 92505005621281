



























































































































import { createComponent, ref, Ref } from "@vue/composition-api"
import { Maybe, User } from "@/generated/graphql"
import { getUser } from "@/api/users"
import { refundExtraPurchase, refundMembership, refundServicePack } from "@/api/purchases"
import { formatAmount, formatDateShort } from "@/utils"

function goToStripe() {
  window.open("https://dashboard.stripe.com/payments")
}

export default createComponent({
  // components: {
  // },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props, { root }) {
    const loading = ref(true)
    const user: Ref<Maybe<User>> = ref(null)

    async function reloadUser() {
      loading.value = true
      user.value = await getUser(props.id)
      loading.value = false
    }
    reloadUser()

    async function handleRefundServicePack(pack) {
      const { successful, result, messages } = await refundServicePack({ id: pack.id })
      if (successful) {
        root.$alert(`Removed ${pack.name} pack.`, `Refunded ${formatAmount(-result.amount)}`, { callback: reloadUser })
        return
      }
      root.$alert(messages[0].message, `Refund failed`)
    }

    async function handleRefundExtraPurchase(extra) {
      const { successful, result, messages } = await refundExtraPurchase({ id: extra.id })
      if (successful) {
        root.$alert(`Removed ${extra.description}`, `Refunded ${formatAmount(-result.amount)}`, {
          callback: reloadUser
        })
        return
      }
      root.$alert(messages[0].message, `Refund failed`)
    }

    async function handleRefundMembership(membership) {
      const { successful, result, messages } = await refundMembership({ id: membership.id })
      if (successful) {
        root.$alert(`Removed ${membership.name} membership`, `Refunded ${formatAmount(-result.amount)}`, {
          callback: reloadUser
        })
        return
      }
      root.$alert(messages[0].message, `Refund failed`)
    }

    return {
      // refs
      loading,
      user,
      // methods
      formatAmount,
      formatDateShort,
      goToStripe,
      handleRefundExtraPurchase,
      handleRefundMembership,
      handleRefundServicePack
    }
  }
})
