






















import { computed, createComponent } from "@vue/composition-api"
import { PossibleAppointment } from "@/generated/graphql"
import { format, parseISO } from "date-fns"
import { calculateServicePurchases } from "@/api/purchases"

export default createComponent({
  props: {
    appointment: {
      type: Object as () => PossibleAppointment,
      required: true
    },
    sameArtist: {
      type: Boolean,
      required: true
    },
    serviceIds: {
      type: Array as () => Array<string>,
      required: true
    },
    userId: {
      type: String,
      required: true
    },
    persons: {
      type: Array,
      required: true
    },
    personsObject: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: false
    }
  },
  setup(props, { root }) {
    const formattedTime = computed(() => {
      const date = parseISO(props.appointment.date + "T" + props.appointment.time)
      return format(date, "EEEE h:mm bbbb")
    })

    const artists = computed(() => {
      return [...new Map(props.appointment.artists.map(artist =>
        [artist["id"], artist])).values()]
    })

    // serviceIdsWithGelRemoval: props.serviceIdsWithGelRemoval,

    async function reserve() {
      const possibleAppointmentServices = props?.appointment?.services?.flat()
      const possibleAppointmentServicesIds = possibleAppointmentServices.map(s => s.id)

      const possibleAppointment = {
        artistIds: props.appointment.artists.map(a => a.id),
        date: props.appointment.date,
        sameArtist: props.sameArtist,
        serviceIds: possibleAppointmentServicesIds,
        time: props.appointment.time,
        userId: props.userId
      }
      await root.$store.dispatch("appointments/rememberPossibleAppointment", possibleAppointment)

      const required = await calculateServicePurchases(props.userId, possibleAppointmentServicesIds, possibleAppointment.date)

      if (required.length > 0) {
        const services = required.map(s => `${s.title} ${s.type}`).join(",")
        const servicesIds = required.map(s => s.id).join(",")
        root.$router.push({
          name: "*-services-purchase-packs",
          params: { id: props.userId },
          query: { services, servicesIds }
        })
        return
      }


      const { successful, result, messages } = await root.$store.dispatch("appointments/createAppointment")
      if (successful) {
        root.$router.push({
          name: "*-services-confirmed",
          params: { id: result.id },
        })
      } else {
        const message = messages[0].message
        alert(message)
      }
    }
    return {
      artists,
      formattedTime,
      reserve
    }
  }
})
