import { query } from "@/api"
import { Location } from "@/generated/graphql"
import gql from "graphql-tag"

export async function listLocations(): Promise<Array<Location>> {
  const response = await query({
    query: gql`
      query Locations {
	locations {
          id
          name
          description
          address
          phoneNumber
          color
          visible
        }
      }
    `
  })
  // Assuming response.data.locations is an array of Location
  const allLocations: Array<Location> = response.data.locations;

  // Filter locations where visible is true
  const visibleLocations: Array<Location> = allLocations.filter(
    (location) => location.visible === true
  );

  return visibleLocations;
}
