import { mutate, query } from "@/api"
import {
  AddPaymentMethodInput,
  AddPaymentMethodPayload,
  RemovePaymentMethodInput,
  RemovePaymentMethodPayload,
  ChargeItemInput,
  ChargeItemPayload,
  CancelMembershipInput,
  CancelMembershipPayload,
  MembershipOption,
  PackOption,
  PurchasePackInput,
  PurchasePackPayload,
  RefundMembershipInput,
  RefundMembershipPayload,
  RefundExtraPurchaseInput,
  RefundExtraPurchasePayload,
  RefundServicePackInput,
  RefundServicePackPayload,
  Report,
  ReportInput,
  MenuService,
  DiscountPricePayload
} from "@/generated/graphql"
import gql from "graphql-tag"

export async function addPaymentMethod(input: AddPaymentMethodInput): Promise<AddPaymentMethodPayload> {
  const response = await mutate({
    mutation: gql`
      mutation AddPaymentMethod($input: AddPaymentMethodInput!) {
        payload: addPaymentMethod(input: $input) {
          successful
          messages {
            code
            field
            message
          }
          result {
            displayName
            paymentMethod
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function removePaymentMethod(input: RemovePaymentMethodInput): Promise<RemovePaymentMethodPayload> {
  const response = await mutate({
    mutation: gql`
      mutation RemovePaymentMethod($input: RemovePaymentMethodInput!) {
        payload: removePaymentMethod(input: $input) {
          successful
          messages {
            code
            field
          }
          result {
            id
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function calculateServicePurchases(userId, serviceIds, date): Promise<Array<MenuService>> {
  const response = await query({
    query: gql`
      query CalculateServicePurchases($userId: ID!, $serviceIds: [ID!]!, $date: Date!) {
        calculateServicePurchases(userId: $userId, serviceIds: $serviceIds, date: $date) {
          id
          title
          type
          slug
          packs {
            id
            quantity
            price
          }
        }
      }
    `,
    variables: { userId, serviceIds, date }
  })
  return response.data.calculateServicePurchases
}

export async function cancelMembership(input: CancelMembershipInput): Promise<CancelMembershipPayload> {
  const response = await mutate({
    mutation: gql`
      mutation CancelMembership($input: CancelMembershipInput!) {
        payload: cancelMembership(input: $input) {
          successful
          result {
            id
            amount
            canceled_at
            name
          }
          messages {
            code
            field
            message
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function chargeItem(input: ChargeItemInput): Promise<ChargeItemPayload> {
  const response = await mutate({
    mutation: gql`
      mutation ChargeItem($input: ChargeItemInput!) {
        payload: chargeItem(input: $input) {
          successful
          messages {
            code
            field
            message
          }
          result {
            amount
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function generateReport(input: ReportInput): Promise<Report> {
  const response = await query({
    query: gql`
      query GenerateReport($input: ReportInput!) {
        report(input: $input) {
          column_widths
          columns
          name
          rows
          title
        }
      }
    `,
    variables: { input }
  })
  return response.data.report
}

export async function listPackOptions(): Promise<Array<PackOption>> {
  const response = await query({
    query: gql`
      query ListPackOptions {
        packOptions {
          id
          name
          count
          amount
          expiration
          services {
            id
            title
            duration
          }
        }
      }
    `
  })
  return response.data.packOptions
}

export async function listMembershipOptions(): Promise<Array<MembershipOption>> {
  const response = await query({
    query: gql`
      query ListMembershipOptions {
        membershipOptions {
          id
          name
          amount
          services {
            id
            title
            duration
          }
        }
      }
    `
  })
  return response.data.membershipOptions
}

export async function purchasePacks(input: PurchasePackInput): Promise<PurchasePackPayload> {
  const response = await mutate({
    mutation: gql`
      mutation PurchasePack($input: PurchasePackInput!) {
        payload: purchasePack(input: $input) {
          successful
          messages {
            code
            field
            message
          }
          result {
            amount
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function refundServicePack(input: RefundServicePackInput): Promise<RefundServicePackPayload> {
  const response = await mutate({
    mutation: gql`
      mutation RefundServicePack($input: RefundServicePackInput!) {
        payload: refundServicePack(input: $input) {
          successful
          result {
            amount
          }
          messages {
            code
            field
            message
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function refundExtraPurchase(input: RefundExtraPurchaseInput): Promise<RefundExtraPurchasePayload> {
  const response = await mutate({
    mutation: gql`
      mutation RefundExtraPurchase($input: RefundExtraPurchaseInput!) {
        payload: refundExtraPurchase(input: $input) {
          successful
          result {
            amount
          }
          messages {
            code
            field
            message
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function refundMembership(input: RefundMembershipInput): Promise<RefundMembershipPayload> {
  const response = await mutate({
    mutation: gql`
      mutation RefundMembership($input: RefundMembershipInput!) {
        payload: refundMembership(input: $input) {
          successful
          result {
            amount
          }
          messages {
            code
            field
            message
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function discountPrice(input: PurchasePackInput): Promise<DiscountPricePayload> {
  const response = await mutate({
    mutation: gql`
      mutation DiscountPrice($input: PurchasePackInput!) {
        discountPrice(input: $input) {
          successful
          result {
            price
            discountFound
          }
          messages {
            code
            field
            message
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.discountPrice
}
