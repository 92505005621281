















import { createComponent, ref, computed } from "@vue/composition-api"
import { useSearchable } from "@/use/use-searchable"
import { listArtists } from "@/api/artists"
import ArtistResultRow from "@/components/admin/artists-row.vue"

export default createComponent({
  components: {
    ArtistResultRow
  },
  setup() {
    const query = ref("")
    const artists = ref([])
    const includeDisabled = ref(false)
    const { searchResults } = useSearchable(artists, ["name", "quote"], query)

    const toggle_label = computed(() => includeDisabled.value ? "Show Active" : "Show Disabled")

    async function fetchAllArtists(includeDisabled) {
      artists.value = await listArtists({ includeDisabled })
    }

    function handleDisabled() {
      includeDisabled.value = !includeDisabled.value
      fetchAllArtists(includeDisabled.value)
    }

    fetchAllArtists(includeDisabled.value)

    return {
      query,
      searchResults,
      includeDisabled,
      toggle_label,
      handleDisabled
    }
  }
})
