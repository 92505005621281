
















































































































import PackSelector from "@/components/admin/packs-selector.vue"
import ListAppointments from "@/components/admin/appointments-list.vue"

import { computed, createComponent, ref, Ref } from "@vue/composition-api"
import { cancelMembership, removePaymentMethod } from "@/api/purchases"
import { formatDateShort, formatPronoun } from "@/utils"
import { getArtistById } from "@/api/artists"
import { Maybe, Artist } from "@/generated/graphql"
import { DateTime as dt } from "luxon"

export default createComponent({
  components: {
    PackSelector,
    ListAppointments
  },

  setup(_props, { root }) {
    const artist: Ref<Maybe<Artist>> = ref({})
    const drawerRef = ref(null)
    const viewerRef = computed(() => root.$store.state.auth.user)
    const startAtRef = computed(() => dt.local().toJSDate())
    const isRemoving = ref(false)
    const isArtist = computed(() => root.$store.getters["auth/isArtist"])

    root.$nextTick(async () => {
      artist.value = await getArtistById(viewerRef.value.id)
    })

    function handleRemoveCC() {
      root.$alert(`${viewerRef.value.paymentMethod}.`, "Are you sure you want to remove", {
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        cancelButtonClass: "el-button--cancel",
        callback: actionType => {
          if (actionType === "cancel") return
          handleRemovePaymentMethod()
        }
      })
    }

    async function handleRemovePaymentMethod() {
      try {
        isRemoving.value = true
        await removePaymentMethod({ userId: viewerRef.value.id })
        await root.$store.dispatch("auth/refreshViewer")
      } catch (error) {
        root.$alert("Failed to remove credit card", { type: "error" })
        console.log("remove cc error", error)
      } finally {
        isRemoving.value = false
      }
    }

    async function handleCancelMembership() {
      try {
        const { value } = (await root.$prompt(
          `Are you sure you want to cancel your membership? Membership periods extend for three months after you
          purchased your membership. If you cancel before three months, you will still be charged for any remaining
          months. Your membership will remain usable, but it will not renew after three months. Type CANCEL in
          all caps to cancel your membership.`,
          "Cancel Membership",
          {
            confirmButtonText: "Cancel membership",
            showCancelButton: false,
            inputType: "text",
            inputValidator: value => value === "CANCEL",
            inputErrorMessage: "Type CANCEL in all caps."
          }
        )) as any

        if (value === "CANCEL") {
          const { successful } = await cancelMembership({ id: viewerRef.value.membership.id })
          if (successful) {
            root.$store.dispatch("auth/refreshViewer")
          }
        }
      } catch {
        // ignore
      }
    }

    return {
      artist,
      drawerRef,
      viewerRef,
      startAtRef,
      isArtist,
      isRemoving,
      // methods
      handleCancelMembership,
      handleRemoveCC,
      formatDateShort,
      formatPronoun
    }
  }
})
