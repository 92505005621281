import { render, staticRenderFns } from "./tile-pack.vue?vue&type=template&id=96214c72&scoped=true&"
import script from "./tile-pack.vue?vue&type=script&lang=ts&"
export * from "./tile-pack.vue?vue&type=script&lang=ts&"
import style0 from "./tile-pack.vue?vue&type=style&index=0&id=96214c72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96214c72",
  null
  
)

export default component.exports