














import { createComponent, ref, Ref } from "@vue/composition-api"
import ClientAppointment from "@/components/client/appointments-client.vue"
import { getAppointment } from "@/api/appointments"
import { Appointment, Maybe } from "@/generated/graphql"
import { format, parseISO } from "date-fns"

import ics from '@/utils/ics'

export default createComponent({
  components: {
    ClientAppointment
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props, { root }) {
    const appointment = ref(null) as Ref<Maybe<Appointment>>
    const loading = ref(true)

    const formatStartAt = (startAt) => {
      const date = parseISO(startAt)
      return format(date, "h:mm bbbb")
    }

    function summator(arr) {
      return Array.isArray(arr) ? arr.reduce((accumulator, currentValue) =>
        (Array.isArray(accumulator) ? summator(accumulator) : accumulator) + (Array.isArray(currentValue) ? summator(currentValue) : currentValue)) : arr
    }

    // before the Artists label I use this hidden line-break — '⠀'
    // 'Services: ' + appointment.value.appointmentServices.map(s => s.services.map(s => s.title + " " + s.type).join(", ")) +
    function createEvent () {
      let cal = ics();
      let subject = 'Archer Nailbar Appointment w/ ' + appointment.value.artists.map(artist => { return artist.name }).join(', ')
      let description = appointment.value.appointmentServices.map(a => a.artist.name + " @ " +  formatStartAt(appointment.value.date + ' ' + appointment.value.time) + " \\n" + a.services.map(s => [s.title, s.type].join(" ") + ' - ' + summator(s.duration) + ' mins').join(", ")).join(". \\n\\n")
      let location = 'Archer Nail Bar \\n2187 Union Street\\nSan Francisco CA 94123'
      let servicesMapped = appointment.value.appointmentServices
          .map(appointmentService => {
            let totalDuration = appointmentService.services.reduce((ongoing, service) => { return ongoing + service.duration }, 0)
            var endDateTime = new Date(appointmentService.startAt)
            endDateTime.setMinutes(endDateTime.getMinutes() + totalDuration)
            return {
              startAt: new Date(appointmentService.startAt),
              endTime: endDateTime
            }
          })
      let begin = servicesMapped.sort((a: any, b: any) => a.startAt - b.startAt)[0].startAt
      let end = servicesMapped.sort((a: any, b: any) => b.endTime - a.endTime)[0].endTime

      cal.addEvent(subject, description, location, begin.setMinutes(begin.getMinutes() + begin.getTimezoneOffset()),  end.setMinutes(end.getMinutes() + end.getTimezoneOffset()))
      cal.download(subject, '.ics');
    }

    root.$nextTick(async () => {
      const a = await getAppointment(props.id)
      appointment.value = a
      loading.value = false
    })

    return { appointment, loading, createEvent, formatStartAt }
  }
})
