






































































































import { createComponent } from "@vue/composition-api"
import Rituals from "@/components/client/menu-rituals.vue"

export default createComponent({
  components: {
    Rituals
  }
})
