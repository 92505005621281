import { query } from "@/api"
import { MenuServiceCategory } from "@/generated/graphql"
import gql from "graphql-tag"

export async function listServiceCategories(): Promise<Array<MenuServiceCategory>> {
  const response = await query({
    query: gql`
      query MenuServiceCategories {
        menuServiceCategories {
          id
          title
          memberships {
            id
            price
            description
          }
          services {
            id
            duration
            type
            title
            description
            inserted_at
            packs {
              id
              quantity
              price
            }
          }
        }
      }
    `
  })
  return response.data.menuServiceCategories
}
