import { computed, onMounted, onUnmounted, ref } from "@vue/composition-api"

export function useWindowWidth() {
  const innerWidthRef = ref(window.innerWidth)
  const isMobileRef = computed(() => innerWidthRef.value <= 864)
  function onResize() {
    innerWidthRef.value = window.innerWidth
  }
  onMounted(() => window.addEventListener("resize", onResize))
  onUnmounted(() => window.removeEventListener("resize", onResize))
  return { innerWidthRef, isMobileRef }
}
