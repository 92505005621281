






































































import { createComponent, reactive, ref, toRefs } from "@vue/composition-api"
import { listUsersForArtist } from "@/api/users"
import { getAdminArtist } from "@/api/artists"
import { Maybe, Artist } from "@/generated/graphql"
import { useCloudinary } from "@/use/use-cloudinary"

export default createComponent({
  props: {
    id: String
  },
  setup({ id }, { root }) {
    const users = ref([])
    const state = reactive({
      form: {
        bio: "",
        id: "",
        music: "",
        name: "",
        polish: "",
        quote: "",
        userId: "",
        disabled: false
      },
      errors: {
        bio: "",
        music: "",
        name: "",
        polish: "",
        quote: "",
        userId: ""
      },
      submitting: false
    })

    async function fetchUsersForArtist() {
      const results = await listUsersForArtist()
      users.value = results.sort((a, b) => a.fullName.localeCompare(b.fullName))

      const artist: Maybe<Artist> = await getAdminArtist(id)
      state.form.bio = artist.bio
      state.form.id = artist.id
      state.form.music = artist.music
      state.form.name = artist.name
      state.form.polish = artist.polish
      state.form.quote = artist.quote
      state.form.userId = artist.user.id
      state.form.disabled = !!artist.disabledAt
    }
    fetchUsersForArtist()

    async function updateArtist() {
      state.submitting = true
      state.errors.bio = ""
      state.errors.music = ""
      state.errors.name = ""
      state.errors.polish = ""
      state.errors.quote = ""
      state.errors.userId = ""

      const { successful, result, messages } = await root.$store.dispatch("artists/updateArtist", state.form)
      state.submitting = false
      if (!successful) {
        messages.forEach(({ field, message }) => {
          state.errors[field] = message
        })
      } else {
        root.$router.push({ name: "!-artists-show", params: { id: result.id } })
      }
    }

    // cloudinary uploads
    const config = root.$store.state.auth.configuration
    const { uploadWidget } = useCloudinary(config.cloudinaryCloudName, config.cloudinaryUploadPreset, async info => {
      const { successful, messages } = await root.$store.dispatch("artists/updateArtist", {
        id,
        cloudinaryPublicId: info.public_id
      })
      if (successful) {
        root.$router.push({ name: "!-artists-show", params: { id } })
        return
      }
      alert(messages[0].message)
    })
    function openUploader() {
      uploadWidget.open()
    }

    return {
      users,
      updateArtist,
      openUploader,
      ...toRefs(state)
    }
  }
})
