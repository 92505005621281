




































import { computed, createComponent, ref, Ref } from "@vue/composition-api"
import ClientAppointment from "@/components/client/appointments-client.vue"
import { listAppointments } from "@/api/appointments"
import { Appointment } from "@/generated/graphql"
import { DateTime as dt } from "luxon"

export default createComponent({
  components: {
    ClientAppointment
  },
  setup(_props, { root }) {
    const appointments = ref([]) as Ref<Appointment[]>
    const loading = ref(true)
    const user = computed(() => root.$store.state.auth.user)

    const pastAppointments = computed(() => appointments.value.filter(appointment => {
      return  dt.fromSQL(`${appointment.date} ${appointment.time}`) < dt.local()
    })
    )

    const futureAppointments = computed(() => appointments.value.filter(appointment => {
      return dt.fromSQL(`${appointment.date} ${appointment.time}`) > dt.local()
    })
    )

    root.$nextTick(async () => {
      appointments.value = await listAppointments({ userIds: [user.value.id] })
      loading.value = false
    })

    return { appointments, pastAppointments, futureAppointments, user, loading }
  },

})
