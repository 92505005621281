import Vue from "vue"
import Vuex, { ActionTree, GetterTree, MutationTree } from "vuex"
import * as FullStory from "@fullstory/browser"

import appointments, { State as AppointmentsState } from "./modules/appointments"
import artists, { State as ArtistsState } from "./modules/artists"
import admins, { State as AdminsState } from "./modules/admins"
import auth, { State as AuthState } from "./modules/auth"
import users, { State as UsersState } from "./modules/users"
import signUp, { State as SignUpState } from "./modules/signUpForm"

Vue.use(Vuex)

export interface RootState {
  // Note: in JS, to access state in a submodule, you would use `store.state.module.property`. This doesn't work in
  // TS, so this hack is necessary to trick TS into allowing access to modules through the state.
  appointments: AppointmentsState
  artists: ArtistsState
  admins: AdminsState
  auth: AuthState
  users: UsersState
  signUp: SignUpState
}

FullStory.init({ orgId: "TCGCG" })

const state: RootState | (() => RootState) = {} as RootState

const getters: GetterTree<RootState, RootState> = {}

const actions: ActionTree<RootState, RootState> = {}

const mutations: MutationTree<RootState> = {}

export default new Vuex.Store<RootState>({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    appointments,
    artists,
    admins,
    auth,
    users,
    signUp
  },
  state,
  getters,
  actions,
  mutations
})
