













import { computed, createComponent, ref, watch } from "@vue/composition-api"

export default createComponent({
  props: {
    min: {
      type: Number,
      required: true
    },
    schedule: {
      type: Object,
      required: false
    },
    max: {
      type: Number,
      required: true
    },
    shortestInterval: {
      type: Number,
      required: true
    },
    value: {
      type: Object, // { startAt: -1, endAt: -1 }
      required: false
    },
    isDisabled: {
      type: Boolean,
      required: false
    }

  },
  setup(props, { emit }) {
    // units are in terms of quarter hours, from 0-95 in a day. -1 means unselected to the time picker.
    const startAt = ref(props.value.startAt || -1)
    const endAt = ref(props.value.endAt || -1)

    const startPickerMax = props.max - props.shortestInterval
    const endPickerMin = computed(() => {
      if (startAt.value < 0) {
        // start time picker is unselected
        return props.min + props.shortestInterval
      }
      // start time picker has a selected value
      return startAt.value + props.shortestInterval
    })

    watch([startAt, endAt], (newValues, oldValues) => {
      if (oldValues && newValues != oldValues) {
        const [newStartAt, newEndAt] = newValues
        if (newStartAt >= props.min && newStartAt < newEndAt) {
          emit("input", { startAt: newStartAt, endAt: newEndAt })
        }
      }
    })

    return { endAt, endPickerMin, startAt, startPickerMax }
  }
})
