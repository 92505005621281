






















import { createComponent } from "@vue/composition-api"
import ArtistFilterResultRow from "@/components/common/artist-filter-row.vue"

export default createComponent({
  components: {
    ArtistFilterResultRow
  },
  props: {
    availableArtists: {
      type: Array,
      required: true
    },
    selectedArtists: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    function handleClearAll() {
      emit("update", [])
    }
    function handleToggle(artist) {
      const artists = [...props.selectedArtists]
      const index = artists.indexOf(artist)
      if (index === -1) {
        artists.push(artist)
      } else {
        artists.splice(index, 1)
      }
      emit("update", artists)
    }
    return {
      handleClearAll,
      handleToggle
    }
  }
})
