import { mutate } from "@/api"
import {
  CreateWorkScheduleInput,
  CreateWorkSchedulePayload,
  DeleteWorkScheduleInput,
  DeleteWorkSchedulePayload,
  UpdateWorkScheduleInput,
  UpdateWorkSchedulePayload
} from "@/generated/graphql"
import gql from "graphql-tag"

export async function createWorkSchedule(input: CreateWorkScheduleInput): Promise<CreateWorkSchedulePayload> {
  const response = await mutate({
    mutation: gql`
      mutation CreateWorkSchedule($input: CreateWorkScheduleInput!) {
        payload: createWorkSchedule(input: $input) {
          successful
          messages {
            code
            field
            message
          }
          result {
            id
            date
            startTime
            endTime
            location {
              id
              name
              address
            }
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function deleteWorkSchedule(input: DeleteWorkScheduleInput): Promise<DeleteWorkSchedulePayload> {
  const response = await mutate({
    mutation: gql`
      mutation DeleteWorkSchedule($input: DeleteWorkScheduleInput!) {
        payload: deleteWorkSchedule(input: $input) {
          successful
          messages {
            code
            field
            message
          }
          result {
            id
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function updateWorkSchedule(input: UpdateWorkScheduleInput): Promise<UpdateWorkSchedulePayload> {
  const response = await mutate({
    mutation: gql`
      mutation UpateWorkSchedule($input: UpdateWorkScheduleInput!) {
        payload: updateWorkSchedule(input: $input) {
          successful
          messages {
            code
            field
            message
          }
          result {
            id
            date
            startTime
            endTime
            location {
              id
              name
              address
            }
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}
