import PassThrough from "@/components/common/pass-through.vue"

import PublicHomepage from "@/views/homepage.vue"
import PublicAbout from "@/views/about.vue"
import PublicArtists from "@/views/artists.vue"
import PublicArtistsShow from "@/views/artists-show.vue"
import PublicMenu from "@/views/menu.vue"
import PublicPasswordReset from "@/views/password-reset.vue"
import PublicResetPasswordLanding from "@/views/reset-password-landing.vue"
import PublicPrivacy from "@/views/privacy.vue"
import PublicSignIn from "@/views/sign-in.vue"
import PublicSignUp from "@/views/sign-up.vue"
import PublicTerms from "@/views/terms.vue"

import ClientAccount from "@/views/account/account.vue"
import ClientAccountEdit from "@/views/account/account-edit.vue"
import ClientAppointments from "@/views/account/appointments.vue"
import ClientPaymentMethod from "@/views/account/payment-method.vue"
import ClientServices from "@/views/account/services.vue"
import ClientServicesAvailability from "@/views/account/services-availability.vue"
import ClientServicesConfirmed from "@/views/account/services-confirmed.vue"
import ClientServicesPurchasePacks from "@/views/account/services-purchase.vue"
import { queryStringToJSON } from "@/utils/urls"

// TODO i'm adding these import statements for testing.
import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

// These are top-level public routes starting with /, no authentication is required.
const publicRoutes = [
  { path: "", name: "*-homepage", component: PublicHomepage, meta: { fletchingTexture: true, noMobileFooter: true } },
  { path: "about", name: "*-about", component: PublicAbout, meta: { whiteBackground: true } },
  { path: "artists", name: "*-artists", component: PublicArtists, meta: { fletchingTexture: true } },
  { path: "artists/:id", name: "*-artists-show", component: PublicArtistsShow, props: true, meta: { backArrow: true } },
  { path: "menu", name: "*-menu", component: PublicMenu, meta: { whiteBackground: true } },
  { path: "password-reset", name: "*-password-reset", component: PublicPasswordReset, meta: { noLayout: true } },
  { path: "privacy", name: "*-privacy", component: PublicPrivacy },
  {
    path: "reset-password/:id",
    name: "*-reset-password",
    component: PublicResetPasswordLanding,
    props: true,
    meta: { noLayout: true }
  },
  { path: "sign-in", name: "*-sign-in", component: PublicSignIn, meta: { noLayout: true } },
  { path: "sign-out", name: "*-sign-out", meta: { signOut: true } },
  { path: "sign-up", name: "*-sign-up", component: PublicSignUp, meta: { noMobileFooter: true } },
  // The auth redirect will redirect the user after signing in or signing up by accessing the redirect query in the url
  {
    path: "sign-in",
    name: "*-auth-redirect",
    component: PublicSignIn,
    meta: { noLayout: true },
    redirect: to => {
      const { query } = to
      if (query.redirect) {
        // if no "?" [0] will return the redirect path
        let redirect = query.redirect.split("?")[0]
        let queryStr = query.redirect.split("?")[1]

        // if queryStr == undefined, we get null.
        let params = queryStringToJSON(queryStr)
        return { path: `${redirect}`, query: params }
      } else {
        return { path: "/", query: null }
      }
    }
  },
  { path: "terms", name: "*-terms", component: PublicTerms }
]

// Client-facing, authenticated routes nest under /account, and require a signed in account.
const authenticatedParentRoute = {
  path: "account",
  component: PassThrough,
  meta: { requiresViewer: true },
  children: [
    {
      path: "",
      name: "*-account",
      component: ClientAccount
    },
    { path: "edit/:id", name: "*-account-edit", component: ClientAccountEdit, props: true, meta: { backArrow: true } },
    { path: "appointments", name: "*-appointments", component: ClientAppointments },
    {
      path: "services",
      name: "*-services",
      component: ClientServices,
      meta: { noMobileFooter: true }
    },
    {
      path: "services/availablility",
      name: "*-services-availability",
      component: ClientServicesAvailability,
      meta: { backArrow: true, noMobileFooter: true }
    },
    { path: "services/confirmed/:id", name: "*-services-confirmed", component: ClientServicesConfirmed, props: true },
    {
      path: "services/purchase/:id",
      name: "*-services-purchase-packs",
      component: ClientServicesPurchasePacks,
      props: true,
      meta: { backArrow: true, noMobileFooter: true }
    },
    {
      path: "payment-method/:id",
      name: "*-payment-method",
      component: ClientPaymentMethod,
      props: true,
      meta: { backArrow: true }
    }
  ]
}

export const clientRoutes = [
  {
    path: "/",
    component: PassThrough,
    meta: { isClientFacing: true },
    children: [...publicRoutes, authenticatedParentRoute]
  }
]
