
















import { createComponent } from "@vue/composition-api"

export default createComponent({
  props: {
    artist: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    }
  }
})
