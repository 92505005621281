






import { createComponent } from "@vue/composition-api"
import ServiceContainer from "@/components/client/services-container.vue"

export default createComponent({
  components: {
    ServiceContainer
  },
  setup(_props, { root }) {
    const userId = root.$route.query.userId
    return {
      userId
    }
  }
})
