








import { createComponent } from "@vue/composition-api"
import LayoutHeader from "./header.vue"
import LayoutFooter from "./footer.vue"

export default createComponent({
  components: {
    LayoutHeader,
    LayoutFooter
  }
})
