


















import { createComponent, ref, Ref, watch } from "@vue/composition-api"
import { format, parseISO } from "date-fns"
import AppointmentRow from "@/components/admin/appointments-row.vue"
import { Appointment } from "@/generated/graphql"
import { listCancelledAppointments } from "@/api/appointments"

export default createComponent({
  components: {
    AppointmentRow
  },
  props: {
    artistId: {
      type: String,
      required: false
    },
    userId: {
      type: String,
      required: false
    },
    locationId: {
      type: String,
      required: false
    },
    limit: {
      type: Number,
      required: false
    },
    startAt: {
      type: Date,
      required: false
    },
    endAt: {
      type: Date,
      required: false
    },
    reversed: {
      type: Boolean,
      required: false
    },
    daySeparator: {
      type: Boolean,
      required: false
    }
  },
  setup(props) {
    const appointments = ref([]) as Ref<Array<Appointment>>
    const appointmentsWithDays = ref({})

    function formatDay(day) {
      return format(parseISO(day) as Date, "EEEE, MMMM do")
    }

    watch(async () => {
      const filters = {}
      if (props.artistId) {
        filters["artistIds"] = [props.artistId]
      }
      if (props.userId) {
        filters["userIds"] = [props.userId]
      }
      if (props.startAt) {
        filters["startAt"] = props.startAt
      }
      if (props.endAt) {
        filters["endAt"] = props.endAt
      }
      if (props.locationId) {
        filters["locationId"] = props.locationId
      }

      const results = await listCancelledAppointments(filters)

      appointments.value = props.limit ? results.slice(0, props.limit) : results

      if (props.reversed) {
        appointments.value.reverse()
      }

      if (props.daySeparator) {
        appointmentsWithDays.value = appointments.value.reduce((rv, x) => {
          ;(rv[x["date"]] = rv[x["date"]] || []).push(x)
          return rv
        }, {})
      }
    })
    return { appointments, appointmentsWithDays, formatDay }
  }
})
