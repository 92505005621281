









import { createComponent } from "@vue/composition-api"

export default createComponent({
  props: {
    pack: {
      type: Object,
      required: true
    },
    count: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    function cssClassForPack(pack) {
      if (pack.quantity === 1) {
        return "one-pack"
      } else if (pack.quantity === 5) {
        return "five-pack"
      } else {
        return "ten-pack"
      }
    }
    let allowClick = true
    function handleClick() {
      if (props.count === 0) {
        if (allowClick) {
          emit("change", props.pack, 1)
          allowClick = false
        } else {
          allowClick = true
        }
      }
    }
    function handleChange(count) {
      emit("change", props.pack, count)
    }
    return {
      cssClassForPack,
      handleChange,
      handleClick
    }
  }
})
