









































import { computed, createComponent, ref } from "@vue/composition-api"
import { format, addWeeks } from "date-fns"
import { quarterHourToTimeScalar, timeScalarToQuarterHour, encodeDateScalar } from "@/utils/date"
import { createWorkSchedule, deleteWorkSchedule, updateWorkSchedule } from "@/api/work_schedules"

import { Artist, WorkSchedule } from "@/generated/graphql"

interface ScheduleWindow {
  id: string
  locationId: string
  startAt: number
  endAt: number
  message: string | null
}

function schedulesOnDate(workSchedules: Array<WorkSchedule>, date: Date, locationId: any) {
  const dateScalar = encodeDateScalar(date)
  return workSchedules
    .filter(workSchedule => workSchedule.date === dateScalar && workSchedule?.location?.id === locationId)
    .map(workSchedule => {
      return {
        id: workSchedule.id,
        locationId: workSchedule?.location?.id,
        startAt: timeScalarToQuarterHour(workSchedule.startTime),
        endAt: timeScalarToQuarterHour(workSchedule.endTime)
      } as ScheduleWindow
    })
    .sort((a, b) => a.startAt - b.startAt)
}

export default createComponent({
  props: {
    artist: {
      type: Object as () => Artist,
      required: true
    },
    date: {
      type: (Date as unknown) as () => Date,
      required: true
    }
  },
  setup(props, { root }) {
    const isArtist = computed(() => root.$store.getters["auth/isArtist"])
    const workSchedules = ref(props.artist.workSchedules)
    const fullDayName = computed(() => format(props.date, "EEEE - MMMM d"))
    const globalLocationId = computed(() => root.$store.getters["admins/currentLocationId"])
    const schedules = computed(() => schedulesOnDate(workSchedules.value, props.date, globalLocationId.value))

    const newPicker = ref(Date.now())

    function showError(messages) {
      root.$message({
        message: messages[0].message,
        type: "warning",
        showClose: true
      })
    }

    function copyLastWeek() {
      const lastWeekSchedules = schedulesOnDate(workSchedules.value, addWeeks(props.date, -1), globalLocationId.value)
      lastWeekSchedules.forEach(async ws => await create(ws))
    }

    async function create({ startAt, endAt }) {
      const startTime = quarterHourToTimeScalar(startAt)
      const endTime = quarterHourToTimeScalar(endAt)

      const input = {
        artistId: props.artist.id,
        date: encodeDateScalar(props.date),
        locationId: globalLocationId.value.toString(),
        startTime,
        endTime
      }

      const response = await createWorkSchedule(input)
      if (response.successful) {
        const newWorkSchedule = response.result
        workSchedules.value = workSchedules.value.concat(newWorkSchedule)
        newPicker.value = Date.now()
      } else {
        showError(response.messages)
      }
    }

    async function update(schedule, { startAt, endAt }) {
      const startTime = quarterHourToTimeScalar(startAt)
      const endTime = quarterHourToTimeScalar(endAt)

      const input = {
        id: schedule.id,
        date: encodeDateScalar(props.date),
        locationId: globalLocationId.value.toString(),
        startTime,
        endTime
      }

      const response = await updateWorkSchedule(input)
      if (response.successful) {
        const updatedWorkSchedule = response.result
        workSchedules.value = workSchedules.value.map(ws =>
          ws.id === updatedWorkSchedule.id ? updatedWorkSchedule : ws
        )
      } else {
        showError(response.messages)
      }
    }

    async function remove(schedule) {
      const input = { id: schedule.id }
      const response = await deleteWorkSchedule(input)
      if (response.successful) {
        workSchedules.value = workSchedules.value.filter(ws => ws.id !== schedule.id)
      } else {
        showError(response.messages)
      }
    }

    return {
      create,
      fullDayName,
      newPicker,
      schedules,
      isArtist,
      remove,
      update,
      copyLastWeek,
      globalLocationId
    }
  }
})
