import ApolloClient, { ApolloQueryResult, DefaultOptions } from "apollo-client"
import { InMemoryCache, NormalizedCacheObject } from "apollo-cache-inmemory"
import { FetchResult } from "apollo-link"
import { createHttpLink } from "apollo-link-http"

import $store from "@/store"

import { sleep } from "@/utils"

let _httpClient: ApolloClient<NormalizedCacheObject>
const maxRetries = 0

export function reset(token: string | null): void {
  const headers = token ? { authorization: `Bearer ${token}` } : {}
  const link = createHttpLink({ headers })
  const cache = new InMemoryCache()
  const defaultOptions: DefaultOptions = {
    // don't cache things and return GraphQL errors in response (don't treat as network errors which is the default)
    mutate: {
      fetchPolicy: "no-cache",
      errorPolicy: "all"
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all"
    },
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "all"
    }
  }
  _httpClient = new ApolloClient({ link, cache, defaultOptions })
}

export async function query(args: any, retryCounter = maxRetries): Promise<ApolloQueryResult<any>> {
  try {
    const response = await _httpClient.query(args)
    if (localStorage.slow) {
      await sleep(localStorage.slow)
    }
    return response
  } catch (e) {
    if (e.networkError) {
      const statusCode = e.networkError.statusCode || 999
      if (statusCode === 401) {
        await $store.dispatch("auth/destroyToken")
      } else if (statusCode >= 500 && retryCounter > 0) {
        console.log(`transient network failure (${statusCode}), retries remaining=${retryCounter}...`)
        await sleep((1 + Math.random() * 2) * 1000)
        return query(args, retryCounter - 1)
      }
    }
    throw e
  }
}

export async function mutate(
  args: any,
  retryCounter = maxRetries
): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> {
  try {
    const response = await _httpClient.mutate(args)
    if (localStorage.slow) {
      await sleep(localStorage.slow)
    }
    return response
  } catch (e) {
    if (e.networkError) {
      const statusCode = e.networkError.statusCode || 999
      if (statusCode === 401) {
        $store.dispatch("auth/destroyToken")
      } else if (statusCode >= 500 && retryCounter > 0) {
        console.log(`transient network failure (${statusCode}), retries remaining=${retryCounter}...`)
        await sleep((1 + Math.random() * 2) * 1000)
        return mutate(args, retryCounter - 1)
      }
    }
    throw e
  }
}

export function subscribe(_args: any) {
  throw Error("requires websocket client")
}
