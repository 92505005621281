

























































import { createComponent, reactive, toRefs } from "@vue/composition-api"

export default createComponent({
  setup(_props, { root }) {
    const state = reactive({
      form: {
        displayName: "",
        fullName: "",
        email: "",
        mobilePhone: "",
        pronoun: "",
        newsletter: false
      },
      errors: {
        displayName: "",
        fullName: "",
        email: "",
        mobilePhone: "",
        pronoun: "",
        newsletter: ""
      },
      submitting: false
    })

    async function createUser() {
      state.submitting = true
      const i = state.form.fullName.indexOf(" ")
      if (i >= 0) {
        state.form.displayName = state.form.fullName.substring(0, i)
      }
      if (!state.form.pronoun) {
        state.form.pronoun = "SHE"
      }
      state.errors.displayName = ""
      state.errors.fullName = ""
      state.errors.email = ""
      state.errors.mobilePhone = ""
      state.errors.pronoun = ""
      state.errors.newsletter = ""

      const { successful, result, messages } = await root.$store.dispatch("users/createUser", state.form)
      state.submitting = false
      if (!successful) {
        messages.forEach(({ field, message }) => {
          state.errors[field] = message
        })
      } else {
        root.$router.push({ name: "!-users-show", params: { id: result.id } })
      }
    }

    return {
      createUser,
      ...toRefs(state)
    }
  }
})
