import PassThrough from "@/components/common/pass-through.vue"

import AdminAppointments from "@/views/admin/appointments.vue"
import AdminAllAppointments from "@/views/admin/all-appointments.vue"
import AdminAllCancelledAppointments from "@/views/admin/all-cancelled-appointments.vue"
import AdminArtists from "@/views/admin/artists.vue"
import AdminArtistsEdit from "@/views/admin/artists-edit.vue"
import AdminArtistsNew from "@/views/admin/artists-new.vue"
import AdminArtistsShow from "@/views/admin/artists-show.vue"
import AdminDashboard from "@/views/admin/dashboard.vue"
import AdminReports from "@/views/admin/reports.vue"
import AdminServices from "@/views/admin/services.vue"
import AdminStatus from "@/views/admin/status.vue"
import AdminUsers from "@/views/admin/users.vue"
import AdminUsersEdit from "@/views/admin/users-edit.vue"
import AdminUsersNew from "@/views/admin/users-new.vue"
import AdminUsersPaymentMethod from "@/views/admin/users-payment-method.vue"
import AdminUsersPurchaseItems from "@/views/admin/users-purchase-items.vue"
import AdminUsersPurchasePacks from "@/views/admin/users-purchase-packs.vue"
import AdminUsersRefund from "@/views/admin/users-refund.vue"
import AdminUsersShow from "@/views/admin/users-show.vue"

import ClientServicesAvailability from "@/views/account/services-availability.vue"

export const children = [
  { path: "dashboard", name: "!-dashboard", component: AdminDashboard, meta: { foo: true } },
  { path: "users", name: "!-users", component: AdminUsers },
  { path: "users/new", name: "!-users-new", component: AdminUsersNew },
  { path: "users/:id", name: "!-users-show", component: AdminUsersShow, props: true },
  { path: "users/:id/edit", name: "!-users-edit", component: AdminUsersEdit, props: true },
  { path: "artists", name: "!-artists", component: AdminArtists },
  { path: "artists/new", name: "!-artists-new", component: AdminArtistsNew },
  { path: "artists/:id", name: "!-artists-show", component: AdminArtistsShow, props: true },
  { path: "artists/:id/edit", name: "!-artists-edit", component: AdminArtistsEdit, props: true },
  { path: "services", name: "!-services", component: AdminServices },
  { path: "services/availability", name: "!-services-availability", component: ClientServicesAvailability },
  { path: "status", name: "!-status", component: AdminStatus },
  {
    path: "users/:id/payment-method",
    name: "!-users-payment-method",
    component: AdminUsersPaymentMethod,
    props: true
  },
  {
    path: "users/:id/purchase-items",
    name: "!-users-purchase-items",
    component: AdminUsersPurchaseItems,
    props: true
  },
  {
    path: "users/:id/purchase-packs",
    name: "!-users-purchase-packs",
    component: AdminUsersPurchasePacks,
    props: true
  },
  {
    path: "users/:id/refund",
    name: "!-users-refund",
    component: AdminUsersRefund,
    props: true
  },
  {
    path: "appointments",
    name: "!-appointments",
    component: AdminAppointments
  },
  {
    path: "all-appointments",
    name: "!-all-appointments",
    component: AdminAllAppointments
  },
  {
    path: "all-cancelled-appointments",
    name: "!-all-cancelled-appointments",
    component: AdminAllCancelledAppointments
  },
  {
    path: "reports",
    name: "!-reports",
    component: AdminReports
  }
]

export const adminRoutes = [{ path: "/admin", component: PassThrough, children, meta: { requiresManager: true } }]
