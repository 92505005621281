import { lightFormat, parse, startOfDay } from "date-fns"

// Convert 'hh:mm:ss' string to quarter-hour of the day (0-95).
export function timeScalarToQuarterHour(time) {
  const hours = parseInt(time.substring(0, 2))
  const minutes = parseInt(time.substring(3, 5))
  return hours * 4 + Math.floor(minutes / 15)
}

// Convert quarter-hour of the way to 'hh:mm:ss' string.
export function quarterHourToTimeScalar(quarterHour) {
  const hours = Math.floor(quarterHour / 4)
  const minutes = 15 * (quarterHour % 4)
  return `${pad(hours)}:${pad(minutes)}:00`
}

// Encode Date as date scalar "yyyy-MM-dd".
export function encodeDateScalar(date) {
  return lightFormat(date, "yyyy-MM-dd")
}

// Decode "yyyy-MM-dd" into Date (local time).
export function decodeDateScalar(string) {
  return parse(string, "yyyy-MM-dd", startOfDay(new Date()))
}

// Encode Date as time scalar "HH:mm:ss".
// export function encodeTimeScalar(date) {
//   return lightFormat(date, "HH:mm:ss")
// }

// Decode "HH:mm:ss" into Date (with date of today, local time).
// export function decodeTimeScalar(string) {
//   return parse(string, "HH:mm:ss", new Date())
// }

function pad(number) {
  return number < 10 ? "0" + number : number
}

// converts a day's quarter hour (0-95) to a local time string.
export function quarterHourToLabel(qh) {
  const hour = Math.floor(qh / 4)
  const min = (15 * (qh % 4)).toString().padStart(2, "0")
  if (hour > 12) {
    return `${hour - 12}:${min}pm`
  } else if (hour == 12) {
    return `12:${min}pm`
  } else if (hour > 0) {
    return `${hour}:${min}am`
  }
  return `12:${min}am`
}
