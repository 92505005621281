








































































import { computed, createComponent, ref } from "@vue/composition-api"
import { format, parseISO } from "date-fns"
import { Appointment } from "@/generated/graphql"
import { cancelAppointment, checkInAppointment } from "@/api/appointments"
import { MessageBoxInputData } from "element-ui/types/message-box"
import { formatPronoun } from "@/utils"

export default createComponent({
  props: {
    appointment: {
      type: Object as () => Appointment,
      required: true
    },
    withActions: {
      type: Boolean,
      required: false,
      default: true
    },
    showCancelled: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  setup(props, { root }) {
    const dateRef = computed(() => parseISO(props.appointment.date + "T" + props.appointment.time))
    const formattedTime = computed(() => format(dateRef.value as Date, "EEEE, MMMM do, h:mm bbbb, yyyy"))
    const formatStartAt = (startAt) => {
      const date = parseISO(startAt)
      return format(date, "h:mm bbbb")
    }
    const canCheckIn = computed(() => {
      const appointmentAt = dateRef.value as Date
      const now = new Date()
      const beforeOrAfter = Math.abs(now.getTime() - appointmentAt.getTime()) / 1000
      return beforeOrAfter < 3600 // can check in within one hour either way
    })
    const canCancel = computed(() => {
      const appointmentAt = dateRef.value as Date
      const now = new Date()
      const after = (now.getTime() - appointmentAt.getTime()) / 1000
      return after < 3600 // can cancel an appointment up to one hour after schedule appointment
    })

    const locationInfo = computed(() => {
      const location = props.appointment?.location
      return `${location?.address}, ${location?.name}`
    })


    // works only with 1 person
    const groupServiceNamesByOnePerson = (services) => {
      const grouped = []
      const hasRemoval = services.filter((service) => service.type === 'Other')
      const groupedNames = []

      if (hasRemoval.length > 0) {
        for (let i = 0; i < services.length; i++) {
          if (services[i].type === 'Manicure') {
            for (let j = 0; j < services.length; j++) {
              if (services[j].slug === 'gel_polish_removal_mani') {
                grouped.push([services[j], services[i]])
                groupedNames.push(services[j].slug)
                groupedNames.push(services[i].slug)
              }
            }
          }

          if (services[i].type === 'Pedicure') {
            for (let j = 0; j < services.length; j++) {
              if (services[j].slug === 'gel_polish_removal_pedi') {
                grouped.push([services[j], services[i]])
                groupedNames.push(services[j].slug)
                groupedNames.push(services[i].slug)
              }
            }
          }
        }
      } else {
        return services
      }

      return [ ...grouped.flat(), ...services.filter(k => !groupedNames.includes(k.slug))]
    }

    const appointmentServices = computed(() => {
      console.log("hui", props.appointment.appointmentServices.find(el => el.id === "330321110543777792"))
      return props.appointment.appointmentServices.map(a => {return a.artist.name + " @ " +  (formatStartAt(a.startAt)) + " - " + groupServiceNamesByOnePerson(a.services).map(fullName).join(", ")})
    })

    async function handleCheckIn() {
      const { successful } = await checkInAppointment({ id: props.appointment.id })
      if (successful) {
        props.appointment.checkedInAt = new Date()
      } else {
        root.$alert("Check failed. Try again in a moment.")
      }
    }

    const fullName = (service) => {
      if (service.type === "Manicure" || service.type === "Pedicure") {
        return `${service.title} ${service.type}`
      } else {
        const packName = service.slug.slice('gel_polish_removal_'.length)
        return `${service.title} ${packName === 'mani' ? 'Fingers' : 'Toes' }`
      }
    }


    const cancelled = ref(false)

    async function handleCancel() {
      try {
        const { value } = (await root.$prompt(
          `Are you sure you want to cancel ${props.appointment.user.fullName}'s appointment? ` +
            "If this cancellation is within 24 hours, you can type 'CANCEL' to NOT refund the credit, or " +
            "you can type 'CREDIT' to put the credit back in their pack. ",
          "Cancel Appointment",
          {
            confirmButtonText: "Cancel appointment",
            showCancelButton: false,
            inputType: "text",
            inputValidator: value => value === "CANCEL" || value === "CREDIT",
            inputErrorMessage: "Type CANCEL or CREDIT in all caps."
          }
        )) as MessageBoxInputData

        if (value === "CANCEL") {
          const { successful } = await cancelAppointment({ id: props.appointment.id })
          if (successful) {
            cancelled.value = true
          }
        } else if (value === "CREDIT") {
          const { successful } = await cancelAppointment({ id: props.appointment.id, allowLateCancellation: true })
          if (successful) {
            cancelled.value = true
          }
        }
      } catch {
        // ignore
      }
    }

    return {
      cancelled,
      // methods
      groupServiceNamesByOnePerson,
      fullName,
      canCancel,
      formatStartAt,
      canCheckIn,
      formattedTime,
      handleCancel,
      handleCheckIn,
      appointmentServices,
      locationInfo,
      formatPronoun
    }
  }
})
