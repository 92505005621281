































































































































import { computed, createComponent, ref, onMounted } from "@vue/composition-api"
import { listLocations } from "@/api/locations"


export default createComponent({
  setup(_props, { root }) {
    const drawer = ref(false)
    const isClientFacing = computed(() => root.$route.matched.some(r => r.meta.isClientFacing))
    const hasBackArrow = computed(() => root.$route.meta.backArrow)
    const locations = ref([])
    const selectedLocationId = ref(null)
    const selectedLocationColor = computed(() => {
      let selectedLocation = locations.value.find(l => l.id === selectedLocationId.value)
      return selectedLocation?.color || "#ffffff"
    })

    const isAuthenticated = computed(() => root.$store.getters["auth/isAuthenticated"])
    const isManager = computed(() => root.$store.getters["auth/isManager"])
    const bookAppointmentRoute = computed(() => (isAuthenticated.value ? "*-services" : "*-sign-up"))
    const viewerRef = computed(() => root.$store.state.auth.user)
    const NODE_ENV = process.env.NODE_ENV

    onMounted(() => {
      fetchLocations()
    })

    function closeHamburger() {
      drawer.value = false
    }

    async function fetchLocations() {
      locations.value = await listLocations()

      setInitialLocation()
    }

    function setInitialLocation() {
      selectedLocationId.value = localStorage.getItem("admin-location") || locations.value[0]?.id
      setGlobal()
    }

    async function setGlobal() {
      if (selectedLocationId.value) {
        await root.$store.dispatch("admins/rememberGlobalLocationId", selectedLocationId.value)
        localStorage.setItem("admin-location", selectedLocationId.value)
      }
    }

    return {
      isClientFacing,
      hasBackArrow,
      isAuthenticated,
      closeHamburger,
      isManager,
      setGlobal,
      bookAppointmentRoute,
      selectedLocationId,
      selectedLocationColor,
      locations,
      drawer,
      NODE_ENV,
      viewerRef
    }
  }
})
