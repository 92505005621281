import "@/assets/scss/archer.scss"

import Vue from "vue"
import Vue2TouchEvents from "vue2-touch-events"
import VueCompositionApi from "@vue/composition-api"
import Element from "element-ui"

import locale from "element-ui/lib/locale/lang/en"

import i18n from "@/i18n"
import { makeRouter } from "@/router"
import store from "@/store"

import App from "@/views/app.vue"

import ArcherDatePicker from "@/components/common/archer-date-picker.vue"
import ArcherDrawer from "@/components/common/archer-drawer.vue"
import ArcherButton from "@/components/common/archer-button.vue"
import ArcherLoader from "@/components/common/archer-loader.vue"
import ArcherTimePicker from "@/components/common/archer-time-picker.vue"
import ArcherTimeRangePicker from "@/components/common/archer-time-range-picker.vue"

Vue.use(Vue2TouchEvents)
Vue.use(VueCompositionApi)
Vue.use(Element, { locale })

Vue.component("archer-button", ArcherButton)
Vue.component("archer-date-picker", ArcherDatePicker)
Vue.component("archer-drawer", ArcherDrawer)
Vue.component("archer-loader", ArcherLoader)
Vue.component("archer-time-picker", ArcherTimePicker)
Vue.component("archer-time-range-picker", ArcherTimeRangePicker)

Vue.config.productionTip = false

function initializeVue() {
  store
    .dispatch("auth/initializeStore")
    .then(() => {
      new Vue({
        i18n,
        router: makeRouter(store),
        store,
        render: h => h(App)
      }).$mount("#app")
    })
    .catch(e => {
      console.error(e)
      alert(e)
    })
}
initializeVue()
