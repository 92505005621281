















import { createComponent } from "@vue/composition-api"
import PackSelector from "@/components/admin/packs-selector.vue"

export default createComponent({
  components: {
    PackSelector
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  filters: {
    oxfordComma: function(list, delimiter = ", ") {
      return list
        .map((item, i) => {
          let n = i + 1

          if (n === 1) {
            item = item[0].toUpperCase() + item.slice(1)
          } else if (n < list.length && n !== 1) {
            item = delimiter + item
          } else if (n === list.length && list.length !== 2) {
            item = ", and " + item
          } else if (n === list.length) {
            item = " and " + item
          }

          return item
        })
        .join("")
    }
  },
  setup(_props, { root }) {
    const query = (root.$route.query.services || "") as string
    const services = query.length > 0 ? query.split(",") : []
    return {
      services
    }
  }
})
