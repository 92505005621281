



































































import { computed, createComponent, ref, watch } from "@vue/composition-api"
import AppointmentFilter from "@/components/common/appointment-filter.vue"
import AvailableAppointment from "@/components/client/appointments-available.vue"
import { listArtists } from "@/api/artists"
import { listPossibleAppointments } from "@/api/appointments"
import { startOfToday, formatISO } from "date-fns"
import { PossibleAppointment, MenuService } from "@/generated/graphql"
import { decodeDateScalar, encodeDateScalar, timeScalarToQuarterHour } from "@/utils/date"
import { gelRemovalService } from "@/api/menu_services"

export default createComponent({
  components: {
    AppointmentFilter,
    AvailableAppointment
  },
  setup(_props, { root }) {
    let dialogVisible = ref(true)
    const serviceIds = (root.$route.query.serviceIds as string).split(",")
    const locationId = ref('') as any
    locationId.value = root.$route.query.locationId
    const hands = root.$route.query.hands
    const feet = root.$route.query.feet
    const personsValues = (root.$route.query.personsValues || [])
    const persons = personsValues.length > 0
      ? arrayToPersons(personsValues)
      : numberToPersons(Number(root.$route.query.persons))
    const personsObject = {mani: hands, pedi: feet}
    const gelRemovalManiService: MenuService | any = ref(null)
    const gelRemovalPediService: MenuService | any = ref(null)
    const sameArtist = root.$route.query.sameArtist === "true"
    const userId = root.$route.query.userId as string
    //const viewer = computed(() => root.$store.state.auth.user)
    const time = parseInt((root.$route.query.time as string) || "0")
    let onlyArtistId = root.$route.query.onlyArtistId as string
    const dateString = root.$route.query.date as string
    const isLoading = ref(false)

    const date = ref(dateString ? decodeDateScalar(dateString) : startOfToday())

    const timeRange = ref(time === 0 ? [32, 80] : [Math.max(time, 32), Math.min(time + 8, 80)]) // 32=8am, 80=8pm
    const availableArtistsRef = ref([])
    const selectedArtistsRef = ref([])
    const possibleAppointments: any = ref(null)

    const appointmentsByDate = computed(() => {
      const dateScalar = encodeDateScalar(date.value)
      const startQh = timeRange.value[0]
      const endQh = timeRange.value[1]
      const byDate = possibleAppointments?.value?.filter(p => {
        const qh = timeScalarToQuarterHour(p.time)
        return p.date === dateScalar && qh >= startQh && qh <= endQh
      })
      const selectedArtistIds = selectedArtistsRef.value.map(a => a.id)
      const byArtist = byDate.filter(p => {
        return p.artists.map(a => a.id).some(id => selectedArtistIds.includes(id))
      })
      return byArtist
    })

    root.$nextTick(async () => {
      const artists = await listArtists()
      availableArtistsRef.value = artists.sort((a, b) => a.name.localeCompare(b.name))
     // possibleAppointments.value = await listPossibleAppointments(serviceIds, sameArtist, null, filters)
      if (onlyArtistId) {
        selectedArtistsRef.value = availableArtistsRef.value.filter(a => a.id === onlyArtistId)
        onlyArtistId = null
      } else {
        selectedArtistsRef.value = [...availableArtistsRef.value]
      }
    })

    watch(date, (date) => {
      fetchPossibleAppointmentsBySelectDate(serviceIds, sameArtist, date, persons)
    })

    function numberToPersons (number) {
      const arrayOfNumbers = []

      for (var i = 0; i < number; i++) {
          arrayOfNumbers.push({
            hands_radio: "No",
            feet_radio: "No",
            hands: false,
            feet: false,
            hasHandsRemoval: i + 1 <= Number(hands),
            hasFeetRemoval: i + 1 <= Number(feet)
          })
      }

      return arrayOfNumbers
    }

    function arrayToPersons (array) {
      const arrayOfObjects = []
      const persons = array.map(person => {
        return {
          hands: typeof person[0] === "string" ? 'true' === person.split(',')[0] : person[0],
          feet: typeof person[1] === "string" ? 'true' === person.split(',')[1] : person[1]
        }
      })

      for (var i = 0; i < persons.length; i++) {
          arrayOfObjects.push({
            hands: persons[i].hands,
            feet: persons[i].feet,
            hasHandsRemoval: i + 1 <= Number(hands),
            hasFeetRemoval: i + 1 <= Number(feet)
          })
      }

      return arrayOfObjects
    }

    function closeModal () {
      dialogVisible.value = false
      fetchPossibleAppointmentsBySelectDate(serviceIds, sameArtist, date.value, persons)
    }

    async function fetchPossibleAppointmentsBySelectDate(serviceIds, sameArtist, date, options) {
      let newDate
      if (date.value) {
        newDate = formatISO(date.value, { representation: 'date' })
      } else {
        newDate = formatISO(date, { representation: 'date' })
      }
      isLoading.value = true

      gelRemovalManiService.value = await gelRemovalService("Manicure")
      gelRemovalPediService.value = await gelRemovalService("Pedicure")

      const removalOptions = options.map((person) => {
        if (person.hands === true && person.feet === true) {
          return [gelRemovalManiService.value.id, gelRemovalPediService.value.id]
        }

        if (person.feet === true) {
          return [gelRemovalPediService.value.id]
        }

        if (person.hands === true) {
          return [gelRemovalManiService.value.id]
        }

        if ( person.hands === false && person.feet === false ) {
          return []
        }
      })

      root.$store.dispatch("appointments/rememberOptions", { removalOptions })
      root.$store.dispatch("appointments/rememberGelRemovalManiServiceId", {id: gelRemovalManiService.value.id})
      root.$store.dispatch("appointments/rememberGelRemovalPediServiceId", {id: gelRemovalPediService.value.id})
      root.$store.dispatch("appointments/rememberLocationId", { id: locationId.value })

      possibleAppointments.value = await listPossibleAppointments(
        serviceIds,
        sameArtist,
        locationId.value,
        null,
        { date: newDate },
        removalOptions
      )

      isLoading.value = false
    }

    function keyFor(p: PossibleAppointment) {
      return `${p.date}-${p.time}-${p.artists.map(x => x.id).join("-")}`
    }

    function handsChange(_, person) {
      person.hands = person.hands_radio == "Yes"
    }

    function feetChange(_, person) {
      person.feet = person.feet_radio == "Yes"
    }

    function locationChange(location) {
      locationId.value = location
      root.$route.query.locationId = location
      fetchPossibleAppointmentsBySelectDate(serviceIds, sameArtist, date, persons)
    }

    return {
      date,
      feetChange,
      handsChange,
      dialogVisible,
      keyFor,
      possibleAppointments,
      closeModal,
      appointmentsByDate,
      serviceIds,
      locationId,
      sameArtist,
      isLoading,
      timeRange,
      locationChange,
      userId,
      availableArtistsRef,
      selectedArtistsRef,
      persons,
      personsObject
    }
  }
})
