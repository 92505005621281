import { mutate, query } from "@/api"
import {
  ChangePasswordInput,
  ChangePasswordPayload,
  CreateUserInput,
  CreateUserPayload,
  RegisterUserInput,
  RegisterUserPayload,
  ResetPasswordInput,
  ResetPasswordPayload,
  UpdateUserInput,
  UpdateUserPayload,
  UpdateUserNotesInput,
  User
} from "@/generated/graphql"
import gql from "graphql-tag"

export async function changePassword(input: ChangePasswordInput): Promise<ChangePasswordPayload> {
  const response = await mutate({
    mutation: gql`
      mutation ChangePassword($input: ChangePasswordInput!) {
        payload: changePassword(input: $input) {
          successful
          messages {
            message
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function createUser(input: CreateUserInput): Promise<CreateUserPayload> {
  const response = await mutate({
    mutation: gql`
      mutation CreateUser($input: CreateUserInput!) {
        payload: createUser(input: $input) {
          successful
          messages {
            code
            field
            message
          }
          result {
            id
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function getUser(id: String): Promise<User | null> {
  const response = await query({
    query: gql`
      query GetUser($id: ID!) {
        user(id: $id) {
          disabledAt
          displayName
          email
          extraPurchases {
            id
            amount
            description
            purchasedAt
          }
          fullName
          id
          insertedAt
          isMember
          isNewsletterSubscriber
          paymentMethod
          photoUrl
          pronoun
          membership {
            amount
            canceled_at
            id
            name
            purchasedAt
            nextChargeAt
            isRecurring
          }
          mobilePhone
          roles
          servicePackCount
          notes
          servicePacks {
            id
            amount
            name
            remaining
            expiresAt
            purchasedAt
          }
        }
      }
    `,
    variables: { id }
  })
  return response.data.user
}

// ADMIN: returns users
export async function listUsers(): Promise<Array<User>> {
  const response = await query({
    query: gql`
      query ListUsers {
        users {
          disabledAt
          email
          fullName
          id
          isMember
          mobilePhone
          photoUrl
          pronoun
          notes
          servicePackCount
        }
      }
    `
  })
  return response.data.users
}

// ADMIN: returns users with offset
export async function batchUsers(offset: Number, filter: String): Promise<Array<User>> {
  const response = await query({
    query: gql`
      query BatchUsers($offset: Int, $filter: String) {
        batchUsers(offset: $offset, filter: $filter) {
          disabledAt
          email
          fullName
          id
          isMember
          mobilePhone
          photoUrl
          pronoun
          notes
          servicePackCount
        }
      }
    `,
    variables: { offset, filter }
  })
  return response.data.batchUsers
}

export async function listUsersForArtist(): Promise<Array<User>> {
  const response = await query({
    query: gql`
      query ListUsers {
        users {
          id
          email
          fullName
        }
      }
    `
  })
  return response.data.users
}

export async function registerUser(input: RegisterUserInput): Promise<RegisterUserPayload> {
  const response = await mutate({
    mutation: gql`
      mutation RegisterUser($input: RegisterUserInput!) {
        payload: registerUser(input: $input) {
          successful
          messages {
            code
            field
            message
          }
          result {
            id
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function resetPassword(input: ResetPasswordInput): Promise<ResetPasswordPayload> {
  const response = await mutate({
    mutation: gql`
      mutation ResetPassword($input: ResetPasswordInput!) {
        payload: resetPassword(input: $input) {
          successful
          messages {
            code
            field
            message
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function updateUser(input: UpdateUserInput): Promise<UpdateUserPayload> {
  const response = await mutate({
    mutation: gql`
      mutation UpdateUser($input: UpdateUserInput!) {
        payload: updateUser(input: $input) {
          successful
          messages {
            code
            field
            message
          }
          result {
            id
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function updateUserNotes(input: UpdateUserNotesInput): Promise<UpdateUserPayload> {
  const response = await mutate({
    mutation: gql`
      mutation UpdateUserNotes($input: UpdateUserNotesInput!) {
        payload: updateUserNotes(input: $input) {
          successful
          messages {
            code
            field
            message
          }
          result {
            id
          }
        }
      }
    `,
    variables: { input }
  })

  return response.data.payload
}
