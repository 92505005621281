import { query } from "@/api"
import { MenuAddon } from "@/generated/graphql"
import gql from "graphql-tag"

export async function listAddons(): Promise<Array<MenuAddon>> {
  const response = await query({
    query: gql`
      query MenuAddons {
        menuAddons {
          id
          title
          price
          duration
          description
        }
      }
    `
  })
  return response.data.menuAddons
}
