


























































import { createComponent, computed, ref } from "@vue/composition-api"
// import { useSearchable } from "@/use/use-searchable"
import { batchUsers } from "@/api/users"
import { formatPronoun } from "@/utils"

export default createComponent({
  setup() {
    const query = ref("")
    const users = ref([])
    const isLoading = ref(true)
    const offset = computed(() => users.value.length)
    // const { searchResults } = useSearchable(users, ["fullName", "email", "mobilePhone"], query)

    async function getInitialState() {
      const newUsers = await batchUsers(offset.value, query.value)
      users.value = newUsers
      isLoading.value = false
    }

    async function getRowsByScroll() {
      isLoading.value = true
      const newUsers = await batchUsers(offset.value, query.value)
      users.value = [...users.value, ...newUsers]
        .filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
      isLoading.value = false
    }

    const handleSearchInputChange = async () => {
      isLoading.value = true
      users.value = []
      const newUsers = await batchUsers(offset.value, query.value)
      users.value = newUsers
      isLoading.value = false
    }

    const scrollHandler = () => {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          isLoading.value = true
          getRowsByScroll()
        }
      }
    }

    getInitialState()
    scrollHandler()

    return {
      query,
      isLoading,
      // searchResults,
      users,
      handleSearchInputChange,
      scrollHandler,
      formatPronoun
    }
  },
})
