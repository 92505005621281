









import { createComponent } from "@vue/composition-api"
import AdditionalInfo from "@/components/client/menu-additional-info.vue"
import NailShapesAndArts from "@/components/client/menu-nail-shapes-and-arts.vue"
import Rituals from "@/components/client/menu-rituals.vue"
import Services from "@/components/client/menu-services.vue"

export default createComponent({
  components: {
    AdditionalInfo,
    NailShapesAndArts,
    Rituals,
    Services
  }
})
