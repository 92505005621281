import { query } from "@/api"
import { MenuService } from "@/generated/graphql"
import gql from "graphql-tag"

export async function listServices(): Promise<Array<MenuService>> {
  const response = await query({
    query: gql`
      query MenuServices {
        menuServices {
          id
          duration
          slug
          title
          type
          description
          inserted_at
          packs {
            id
            quantity
            price
          }
        }
      }
    `
  })
  return response.data.menuServices
}

export async function gelRemovalService(type): Promise<MenuService> {
  const response = await query({
    query: gql`
      query MenuRemovalService($type: String) {
        menuRemovalService(type: $type) {
          id
          duration
          slug
          title
          type
          description
          inserted_at
          packs {
            id
            quantity
            price
          }
        }
      }
    `,
    variables: { type }
  })
  return response.data.menuRemovalService
}
