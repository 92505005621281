



















import { computed, createComponent } from "@vue/composition-api"

export default createComponent({
  setup(_props, { root }) {
    const isAuthenticated = computed(() => root.$store.getters["auth/isAuthenticated"])
    const isClientFacing = computed(() => root.$route.matched.some(r => r.meta.isClientFacing))
    const isManager = computed(() => root.$store.getters["auth/isManager"])
    const noMobileFooter = computed(() => root.$route.meta.noMobileFooter)

    return {
      isAuthenticated,
      isClientFacing,
      isManager,
      noMobileFooter
    }
  }
})
