




import { createComponent } from "@vue/composition-api"
import { RawLocation } from "vue-router"

export default createComponent({
  props: {
    to: {
      type: Object as () => RawLocation,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: "primary"
    }
  },
  setup(props, { root }) {
    function go() {
      root.$router.push(props.to)
    }
    return { go }
  }
})
