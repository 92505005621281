






























































































































































































































































import { createComponent, reactive } from "@vue/composition-api"

export default createComponent({
  setup() {
    const state = reactive({
      color: 1,
      tab: 'play',
    })

    function setCurrentTab(tab) {
      state.tab = tab
    }

    function setCurrentColor(color) {
      state.color = color
    }

    function nextColor() {
      if (state.color === 5) {
        state.color = 1
      } else {
        state.color++
      }
    }

    function previousColor() {
      if (state.color === 1) {
        state.color = 5
      } else {
        state.color--
      }
    }

    return {
      state,
      setCurrentTab,
      setCurrentColor,
      nextColor,
      previousColor
    }
  }
})
