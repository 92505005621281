









import { createComponent, ref } from "@vue/composition-api"
import { listArtists } from "@/api/artists"
import ArtistSummary from "@/components/client/artists-summary.vue"
import shuffle from "lodash-es/shuffle"

export default createComponent({
  components: {
    ArtistSummary
  },
  setup() {
    const artistsRef = ref([])

    async function fetchArtists() {
      artistsRef.value = shuffle(await listArtists())
    }
    fetchArtists()

    return {
      artists: artistsRef
    }
  }
})
