





















import { createComponent, ref, Ref} from "@vue/composition-api"

import ArtistHeader from "@/components/admin/artists-show-header.vue"
import ArtistAppointments from "@/components/admin/artists-show-appointments.vue"
import ArtistDefineSchedule from "@/components/admin/artists-show-define-schedule.vue"

import { getAdminArtist } from "@/api/artists"
import { startOfToday } from "date-fns"

import { Maybe, Artist } from "@/generated/graphql"

export default createComponent({
  components: {
    ArtistHeader,
    ArtistAppointments,
    ArtistDefineSchedule
  },
  props: {
    id: String
  },
  setup(props, { root }) {
    const artist: Ref<Maybe<Artist>> = ref(null)
    const date = ref(startOfToday())
    const tab = ref("0")

    root.$nextTick(async () => {
      artist.value = await getAdminArtist(props.id)
    })

    return {
      artist,
      date,
      tab
    }
  }
})
