import { mutate, query } from "@/api"
import { Configuration, SignInInput, SignInPayload, User, SignOutPayload } from "@/generated/graphql"
import gql from "graphql-tag"

export async function getConfiguration(): Promise<Configuration> {
  const { data } = await query({
    query: gql`
      query GetConfiguration {
        configuration {
          cloudinaryCloudName
          cloudinaryUploadPreset
          stripePublicApiKey
        }
      }
    `
  })
  return data.configuration
}

export async function getViewer(): Promise<User | null> {
  const { data } = await query({
    query: gql`
      query GetViewer {
        viewer {
          displayName
          email
          extraPurchases {
            id
            amount
            description
            purchasedAt
          }
          fullName
          id
          insertedAt
          isMember
          isNewsletterSubscriber
          paymentMethod
          photoUrl
          pronoun
          membership {
            amount
            canceled_at
            id
            name
            purchasedAt
            nextChargeAt
            isRecurring
          }
          mobilePhone
          roles
          servicePackCount
          servicePacks {
            id
            amount
            name
            remaining
            expiresAt
            purchasedAt
          }
        }
      }
    `
  })
  return data.viewer
}

export async function signIn(input: SignInInput): Promise<SignInPayload> {
  const response = await mutate({
    mutation: gql`
      mutation SignIn($input: SignInInput!) {
        payload: signIn(input: $input) {
          successful
          messages {
            code
            message
          }
          result {
            id
          }
        }
      }
    `,
    variables: { input }
  })
  return response.data.payload
}

export async function signOut(): Promise<SignOutPayload> {
  const response = await mutate({
    mutation: gql`
      mutation SignOut {
        payload: signOut {
          successful
        }
      }
    `
  })
  return response.data.payload
}
