






import { createComponent } from "@vue/composition-api"
import PaymentMethod from "@/components/common/payment-method.vue"

export default createComponent({
  components: { PaymentMethod },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props, { root }) {
    function handleAdded(paymentMethod) {
      root.$alert(`Updated payment method to ${paymentMethod}.`, "Success", {
        confirmButtonText: "OK",
        callback() {
          root.$router.push({ name: "!-users-show", params: { id: props.id } })
        }
      })
    }
    return { handleAdded }
  }
})
