





















import { computed, createComponent, ref } from "@vue/composition-api"
import { useWindowWidth } from "@/use/use-window-width"

export default createComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    preface: {
      type: String,
      required: false
    }
  },
  setup(_props, { root }) {
    const { isMobileRef } = useWindowWidth()
    const directionRef = computed(() => (isMobileRef.value ? "btt" : "rtl"))
    const sizeRef = computed(() => (isMobileRef.value ? "100%" : "x"))
    const viewerRef = computed(() => root.$store.state.auth.user)
    const visibleRef = ref(false)

    function close() {
      visibleRef.value = false
    }
    function open() {
      visibleRef.value = true
    }

    return {
      // methods
      close,
      open,
      // refs
      directionRef,
      sizeRef,
      viewerRef,
      visibleRef
    }
  }
})
