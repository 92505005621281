












































import { createComponent, reactive, toRefs } from "@vue/composition-api"
import { getRedirectParams } from "@/utils/urls"

export default createComponent({
  setup(_props, { root }) {
    const state = reactive({
      form: {
        email: "",
        password: ""
      },
      errors: {
        email: "",
        password: ""
      },
      submitting: false
    })

    async function signIn() {
      state.submitting = true
      const { successful } = await root.$store.dispatch("auth/signIn", state.form)
      state.submitting = false
      if (!successful) {
        state.errors.email = "Incorrect email/password."
        return
      }
      if (getRedirectParams()) {
        root.$router.push({ name: "*-auth-redirect", query: { redirect: `${getRedirectParams()}` } })
      } else {
        root.$router.push({ name: "*-homepage" })
      }
    }

    return {
      signIn,
      getRedirectParams,
      ...toRefs(state)
    }
  }
})
