// Convert 'hh:mm:ss' string to quarter-hour of the day (0-95).
export function getRedirectParams() {
  const params = new URL(window.location.toString()).searchParams
  const redirect = params.get("redirect")
  return redirect
}

// convert a query parameters string into a JSON object
export function queryStringToJSON(str) {
  if (str === undefined) {
    return null
  }
  var pairs = str.split("&")
  var result = {}
  pairs.forEach(function(pair) {
    pair = pair.split("=")
    var name = pair[0]
    var value = pair[1]
    if (name.length)
      if (result[name] !== undefined) {
        if (!result[name].push) {
          result[name] = [result[name]]
        }
        result[name].push(value || "")
      } else {
        result[name] = value || ""
      }
  })
  return result
}
