import { ActionTree, GetterTree, Module, MutationTree } from "vuex"
import { RootState } from "@/store"
import * as FullStory from "@fullstory/browser"

import { reset } from "@/api"
import { getConfiguration, getViewer, signIn, signOut } from "@/api/auth"
import { SignInPayload, User } from "@/generated/graphql"

import { death } from "@/utils"

const SESSION_KEY = "session"

export interface State {
  configuration: Object
  initialLoad: boolean
  user: User | null
}

const state: State | (() => State) = {
  configuration: {},
  initialLoad: true,
  user: null
}

const getters: GetterTree<State, RootState> = {
  isAuthenticated() {
    return state.user !== null
  },
  isAdmin() {
    return !!(state.user && state.user.roles.includes("admin"))
  },
  isArtist() {
    return !!(state.user && state.user.roles.includes("artist"))
  },
  isManager() {
    return !!(state.user && (state.user.roles.includes("manager") || state.user.roles.includes("admin")))
  }
}

const actions: ActionTree<State, RootState> = {
  async destroyToken(): Promise<void> {
    localStorage.removeItem(SESSION_KEY)
    window.location.href = "/" // force browser to reload
    await death()
  },

  async initializeStore({ commit, dispatch }) {
    const token = localStorage.getItem(SESSION_KEY)
    if (token) {
      await dispatch("reconnect", token) // returns on success
    } else {
      reset(null)
    }
    const configuration = await getConfiguration()
    commit("SET_CONFIGURATION", configuration)
    commit("INITIALIZED_APP")
  },

  async reconnect({ commit, dispatch }, token): Promise<void> {
    localStorage.setItem(SESSION_KEY, token)
    reset(token)
    const viewer = await getViewer()
    if (viewer) {
      commit("SET_USER", viewer)
      FullStory.identify(viewer.id, {
        displayName: viewer.displayName,
        email: viewer.email
      })
    } else {
      await dispatch("destroyToken")
    }
  },

  async refreshViewer({ commit }): Promise<User | null> {
    const viewer = await getViewer()
    commit("SET_USER", viewer)
    return viewer
  },

  async signIn({ dispatch }, form): Promise<SignInPayload> {
    const response = await signIn(form)
    if (response.successful) {
      const token = response.result!.id
      await dispatch("reconnect", token) // returns on success
    }
    return response
  },

  async signOut({ dispatch }): Promise<void> {
    await signOut()
    await dispatch("destroyToken") // reintro: this.dispatch and track 401 error
    await FullStory.anonymize()
  }
}

const mutations: MutationTree<State> = {
  INITIALIZED_APP(state: State): void {
    state.initialLoad = false
  },

  SET_CONFIGURATION(state: State, configuration: Object): void {
    state.configuration = configuration
  },

  SET_USER(state: State, user: User): void {
    state.user = user
  }
}

const module: Module<State, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
export default module
