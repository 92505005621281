











































import { createComponent } from "@vue/composition-api"
export default createComponent({
  setup() {
    function viewAwsStatusPage() {
      window.open("https://status.aws.amazon.com/")
    }
    return {
      viewAwsStatusPage
    }
  }
})
