























































































import { createComponent, reactive, toRefs } from "@vue/composition-api"
import { registerUser } from "@/api/users"
import { RegisterUserInput } from "@/generated/graphql"
import cloneDeep from "lodash-es/cloneDeep"
import { getRedirectParams } from "@/utils/urls"

export default createComponent({
  setup(_props, { root }) {
    let state = reactive({
      form: {
        displayName: "",
        fullName: "",
        email: "",
        mobilePhone: "",
        pronoun: "",
        password: "",
        newsletter: true
      },
      errors: {
        displayName: "",
        fullName: "",
        email: "",
        mobilePhone: "",
        pronoun: "",
        password: "",
        newsletter: ""
      },
      submitting: false
    })

    if (root.$store.getters["signUp/hasUser"]) {
      const data = root.$store.getters["signUp/getUser"]
      state.form = cloneDeep(data)
    }

    function updateStore() {
      let newFormData = cloneDeep(state.form)
      root.$store.dispatch("signUp/updateUser", newFormData)
    }

    async function submit() {
      state.submitting = true
      const i = state.form.fullName.indexOf(" ")
      if (i >= 0) {
        state.form.displayName = state.form.fullName.substring(0, i)
      } else {
        state.form.displayName = state.form.fullName
      }
      if (!state.form.pronoun) {
        state.form.pronoun = "SHE"
      }
      state.errors.displayName = ""
      state.errors.fullName = ""
      state.errors.email = ""
      state.errors.mobilePhone = ""
      state.errors.pronoun = ""
      state.errors.password = ""
      state.errors.newsletter = ""

      const { successful, result, messages } = await registerUser(state.form as RegisterUserInput)
      state.submitting = false
      if (!successful) {
        messages.forEach(({ field, message }) => {
          state.errors[field] = message
        })
      } else {
        const token = result!.id
        await root.$store.dispatch("auth/reconnect", token)
        if (getRedirectParams()) {
          root.$router.push({ name: "*-auth-redirect", query: { redirect: `${getRedirectParams()}` } })
        } else {
          root.$router.push({ name: "*-homepage" })
        }
      }
    }
    return {
      submit,
      updateStore,
      getRedirectParams,
      ...toRefs(state)
    }
  }
})
