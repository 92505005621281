







import { computed, createComponent } from "@vue/composition-api"
import HomepageAuthenticated from "@/components/client/homepage-authenticated.vue"
import HomepageUnauthenticated from "@/components/client/homepage-unauthenticated.vue"

export default createComponent({
  components: {
    HomepageAuthenticated,
    HomepageUnauthenticated
  },
  setup(_props, { root }) {
    const isAuthenticated = computed(() => root.$store.getters["auth/isAuthenticated"])
    return {
      isAuthenticated
    }
  }
})
