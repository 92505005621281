import { ActionTree, GetterTree, Module, MutationTree } from "vuex"
import { RootState } from "@/store"

export interface State {
  globalLocationId: any
}

const state: State | (() => State) = {
  globalLocationId: null
}

const getters: GetterTree<State, RootState> = {
  currentLocationId() {
    return state.globalLocationId
  }
}

const actions: ActionTree<State, RootState> = {
  async rememberGlobalLocationId({ commit }, id) {
    commit("SET_GLOBAL_LOCATION_ID", id)
  }
}

const mutations: MutationTree<State> = {
  SET_GLOBAL_LOCATION_ID(state, id) {
    state.globalLocationId = id
  }
}

const module: Module<State, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
export default module
