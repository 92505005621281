import { ActionTree, GetterTree, Module, MutationTree } from "vuex"
import { RootState } from "@/store"

import { createUser, updateUser } from "@/api/users"
import { CreateUserPayload, UpdateUserPayload } from "@/generated/graphql"

export interface State {}

const state: State | (() => State) = {}

const getters: GetterTree<State, RootState> = {}

const actions: ActionTree<State, RootState> = {
  async createUser(_, input): Promise<CreateUserPayload> {
    const response = await createUser(input)
    return response
  },

  async updateUser(_, input): Promise<UpdateUserPayload> {
    const response = await updateUser(input)
    return response
  }
}

const mutations: MutationTree<State> = {}

const module: Module<State, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
export default module
