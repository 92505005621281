import { ActionTree, GetterTree, Module, MutationTree } from "vuex"
import { RootState } from "@/store"

import { createArtist, updateArtist } from "@/api/artists"
import { CreateArtistPayload, UpdateArtistPayload } from "@/generated/graphql"

export interface State {}

const state: State | (() => State) = {}

const getters: GetterTree<State, RootState> = {}

const actions: ActionTree<State, RootState> = {
  async createArtist(_, input): Promise<CreateArtistPayload> {
    const response = await createArtist(input)
    return response
  },

  async updateArtist(_, input): Promise<UpdateArtistPayload> {
    const response = await updateArtist(input)
    return response
  }
}

const mutations: MutationTree<State> = {}

const module: Module<State, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
export default module
