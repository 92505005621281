import { render, staticRenderFns } from "./services-confirmed.vue?vue&type=template&id=0325d713&scoped=true&"
import script from "./services-confirmed.vue?vue&type=script&lang=ts&"
export * from "./services-confirmed.vue?vue&type=script&lang=ts&"
import style0 from "./services-confirmed.vue?vue&type=style&index=0&id=0325d713&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0325d713",
  null
  
)

export default component.exports