import { render, staticRenderFns } from "./artist-filter-row.vue?vue&type=template&id=2834f242&scoped=true&"
import script from "./artist-filter-row.vue?vue&type=script&lang=ts&"
export * from "./artist-filter-row.vue?vue&type=script&lang=ts&"
import style0 from "./artist-filter-row.vue?vue&type=style&index=0&id=2834f242&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2834f242",
  null
  
)

export default component.exports