



















































































































import { computed, createComponent, ref, Ref, onMounted } from "@vue/composition-api"
import { purchasePacks, discountPrice } from "@/api/purchases"
import { listServiceCategories } from "@/api/menu_service_categories"
import { gelRemovalService } from "@/api/menu_services"
import { getUser } from "@/api/users"
import { User } from "@/generated/graphql"

import MenuManicures from "@/components/client/menu-manicures.vue"
import MenuPedicures from "@/components/client/menu-pedicures.vue"
import PaymentMethod from "@/components/common/payment-method.vue"
import TileMembership from "@/components/admin/tile-membership.vue"
import TilePack from "@/components/admin/tile-pack.vue"


export default createComponent({
  components: {
    MenuManicures,
    MenuPedicures,
    PaymentMethod,
    TileMembership,
    TilePack
  },
  props: {
    userId: {
      type: String,
      required: true
    },
    adminArea: {
      type: Boolean,
      required: true
    },
    inBookingFlow: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { root }) {
    const query = (root.$route.query.servicesIds || "") as string
    const servicesIds = query.length > 0 ? query.split(",") : []
    const serviceCategories = ref([])
    const manicurePacks = ref([])
    const pedicurePacks = ref([])
    const removalPacks = ref([])
    const personsValues = (root.$route.query.personsValues || "") as string
    const memberships = ref([])
    const gelRemovalMani: any = ref(null)
    const gelRemovalPedi: any = ref(null)
    // const gelRemovalManiPack: any = ref(null)
    // const gelRemovalPediPack: any = ref(null)

    const hands: any = root.$route.query.hands
    const feet: any = root.$route.query.feet
    const persons: any = root.$route.query.persons
    const personsObject: any = ref({mani: hands, pedi: feet})

    onMounted(() => {
      fetchServiceCategories(servicesIds)
    })

    const manicureServices = computed(() => {
      const manicure = serviceCategories.value.find(category => category.title === "Manicure")
      if (servicesIds.length > 0) {
        const possibleManiServices = manicure?.services.filter(service => servicesIds.includes(service.id))
        return possibleManiServices
      } else {
        return manicure?.services
      }
    })

    const pedicureServices = computed(() => {
      const pedicure = serviceCategories.value.find(category => category.title === "Pedicure")
      if (servicesIds.length > 0) {
        const possiblePediServices = pedicure?.services.filter(service => servicesIds.includes(service.id))
        return possiblePediServices
      } else {
        return pedicure?.services
      }
    })

    // const gelRemovalServices = computed(() => {
    //   if (gelRemovalMani.value || gelRemovalPedi.value) {
    //     let removal = [ gelRemovalMani?.value, gelRemovalPedi?.value ]
    //     const possibleRemovalServices = removal?.filter(service => servicesIds.includes(service.id))
    //     return possibleRemovalServices
    //   } else {
    //     return removal
    //   }
    // })

    const gelRemovalServices = computed(() => {
      let removal = []
      if (gelRemovalMani.value) {
        removal.push(gelRemovalMani.value)
      }

      if (gelRemovalPedi.value) {
        removal.push(gelRemovalPedi.value)
      }

      if (removal.length > 0 && servicesIds.length > 0) {
        return removal.filter(service => servicesIds.includes(service.id))
      }

      return removal
    })

    async function fetchServiceCategories(servicesIds) {
      serviceCategories.value = await listServiceCategories()
      gelRemovalMani.value = await gelRemovalService("Manicure")
      gelRemovalPedi.value = await gelRemovalService("Pedicure")
      // gelRemovalManiPack.value = gelRemovalMani.value.packs.find(pack => pack.quantity === 1)
      // gelRemovalPediPack.value = gelRemovalPedi.value.packs.find(pack => pack.quantity === 1)
      manicurePacks.value = extractPacksFrom(manicureServices, "Manicure")
      pedicurePacks.value = extractPacksFrom(pedicureServices, "Pedicure")
      removalPacks.value = extractPacksFrom(gelRemovalServices, "Other")

      const allServiceCategoriesWithMemberships = serviceCategories.value.filter(
        category => category.memberships.length > 0
      )

      if (servicesIds.length > 0) {
        const possibleServiceCategoriesWithMemberships = allServiceCategoriesWithMemberships.filter(category => {
          return category.services.find(service => servicesIds.includes(service.id))
        })

        memberships.value = extractMembershipsFrom(possibleServiceCategoriesWithMemberships)
      } else {
        memberships.value = extractMembershipsFrom(allServiceCategoriesWithMemberships)
      }
    }

    function extractPacksFrom(services, type) {
      if (services.value) {
        const packs = services.value.map(service => {
          return service.packs.map(pack => {
            return {
              id: pack.id,
              price: pack.price,
              quantity: pack.quantity,
              name: nameOfPack(service, pack, type),
              count: 0
            }
          })
        })

        return packs.flat()
      }
    }

    function extractMembershipsFrom(categories) {
      const formattedMemberships = categories.map(category => {
        return category.memberships.map(membership => {
          return {
            id: membership.id,
            price: membership.price,
            name: category.title,
            count: 0
          }
        })
      })

      return formattedMemberships.flat()
    }

    const nameOfPack = (service, pack, type) => {

      if (pack.quantity === 1) {
        if (type === 'Other') {
          const packName = service.slug.slice('gel_polish_removal_'.length)
          return `${service.title} ${packName === 'mani' ? 'Fingers' : 'Toes' }`
        } else {
          return `${service.title} ${type}`
        }
      } else {
        if (type === 'Other') {
          return `${pack.quantity} ${service.title}s`
        } else {
          return `${pack.quantity} ${service.title} ${type}s`
        }
      }
    }

    //const isAdmin = root.$store.getters["auth/isAdmin"]
    //const isManager = root.$store.getters["auth/isManager"]

    const discountCodeRef = ref("")
    const discountPriceRef = ref<Number>(null)
    const discountCodeFoundRef = ref<boolean>(null)

    const discountIconRef = computed(() => {
      if (discountCodeFoundRef.value === null) {
        return ""
      } else if (discountCodeFoundRef.value === true) {
        return "el-icon-success"
      } else if (discountCodeFoundRef.value === false) {
        return "el-icon-error"
      }
    })

    const maniDrawerRef = ref(null)
    const pediDrawerRef = ref(null)

    const viewer = computed(() => root.$store.state.auth.user)
    const user = ref(null) as Ref<User | null>
    const submitting = ref(false)

    // memberships
    const selectedMembershipId = ref("")

    function getSelectedMembership() {
      return memberships.value.find(m => m.id === selectedMembershipId.value)
    }
    function handleMembershipSelect(membership) {
      const newValue = selectedMembershipId.value !== membership.id ? membership.id : ""
      selectedMembershipId.value = newValue
      clearDiscountStuff()
    }

    function clearDiscountStuff() {
      discountPriceRef.value = null,
      discountCodeFoundRef.value = null
      discountCodeRef.value = ""
    }
    // packs

    function updatePackCount(packList, packId, count) {
      return packList.map(pack => {
        if (pack.id === packId) {
          return { ...pack, count }
        }
        return pack
      })
    }

    function handlePackChange(pack, count) {
      manicurePacks.value = updatePackCount(manicurePacks.value, pack.id, count)
      pedicurePacks.value = updatePackCount(pedicurePacks.value, pack.id, count)
      removalPacks.value = updatePackCount(removalPacks.value, pack.id, count)
      clearDiscountStuff()
    }

    // price
    const normalPriceRef = computed(() => {
      let tally = 0
      const m = getSelectedMembership()
      if (m) {
        tally += m.price
      }
      const maniTally = manicurePacks.value.reduce((acc, pack) => {
        if (pack.count > 0) {
          return acc + pack.count * pack.price
        }
        return acc
      }, 0)
      const pediTally = pedicurePacks.value.reduce((acc, pack) => {
        if (pack.count > 0) {
          return acc + pack.count * pack.price
        }
        return acc
      }, 0)

      return (tally + maniTally + pediTally) as number
    })

    const totalPrice = computed(() => {
      if (discountPriceRef.value > 0) {
        return discountPriceRef.value
      }
      else if (discountPriceRef.value === 0) {
        return 0
      }
      else {
        return normalPriceRef.value
      }
    })

    // kick off data load
    root.$nextTick(async () => {
      user.value = await getUser(props.userId)
    })
    // added

    async function handleAdded() {
      user.value = await getUser(props.userId)
    }

    async function handleDiscountPrice() {
      try {
        const membershipId = getSelectedMembership() ? getSelectedMembership().id : null
        const manis = manicurePacks.value.flatMap(pack => Array(pack.count).fill(pack.id))
        const pedis = pedicurePacks.value.flatMap(pack => Array(pack.count).fill(pack.id))
        const packIds = [...manis, ...pedis]

        const { successful, result } = await discountPrice({
          userId: props.userId,
          membershipId,
          packIds,
          discountCode: discountCodeRef.value
        })

        if (successful) {
          discountPriceRef.value = result.price
          discountCodeFoundRef.value = result.discountFound
        }

      } catch (e) {
        submitting.value = false
        alert(e)
      }
    }

    const checkPacksCounts = computed(() => {
      const manis = manicurePacks.value.flatMap(pack => Array(pack.count).fill(pack.id))
      const pedis = pedicurePacks.value.flatMap(pack => Array(pack.count).fill(pack.id))
      const removals = removalPacks.value.flatMap(pack => Array(pack.count).fill(pack.id))

      return [...manis, ...pedis, ...removals]
    })

    async function handlePurchase() {

      try {
        // const gelRemovalInServices: any = servicesIds.filter(id => id === gelRemovalMani.value.id || id === gelRemovalPedi.value.id)

        // const gelRemovalPackIds: any = gelRemovalInServices.map(id => {
        //   return id === gelRemovalMani?.value.id ? gelRemovalManiPack.value?.id : gelRemovalPediPack.value?.id
        // })

        const membershipId = getSelectedMembership() ? getSelectedMembership().id : null
        const manis = manicurePacks.value.flatMap(pack => Array(pack.count).fill(pack.id))
        const pedis = pedicurePacks.value.flatMap(pack => Array(pack.count).fill(pack.id))
        const removals = removalPacks.value.flatMap(pack => Array(pack.count).fill(pack.id))
        const packIds = [...manis, ...pedis, ...removals]

        submitting.value = true

        const { successful, messages } = await purchasePacks({
          userId: props.userId,
          membershipId,
          packIds,
          discountCode: discountCodeRef.value
        })

        // submitting.value = false // premature disabling loading state

        // console.log("Success purchase:", successful)
        if (successful) {
          clearDiscountStuff()
          const appointment = root.$store.state.appointments.possibleAppointment
          if (!appointment) {
            if (props.adminArea) {
              submitting.value = false
              root.$router.push({ name: "!-users-show", params: { id: props.userId } })
            } else {
              await root.$store.dispatch("auth/refreshViewer")
              submitting.value = false
              root.$router.push({ name: "*-account" })
            }
            return
          }

          // NOTE: here possible problem with function crashing, in case when dispatch below goes to error;
          const { successful: created, result, messages } = await root.$store.dispatch("appointments/createAppointment")
          // console.log("Success create appointment", created)

          if (created) {
            submitting.value = false
            root.$router.push({ name: "*-services-confirmed", params: { id: result.id } })
            return
          }

          submitting.value = false
          root.$alert(messages[0].message, "Appointed Not Booked", {
            confirmButtonText: "Find another.",
            async callback() {
              const routeName =
                viewer.value.id === appointment.userId ? "*-services-availability" : "!-services-availability"
              const userId = appointment.userId
              const serviceIds = appointment.serviceIds.join(",")

              const gelRemovalServicesIds = gelRemovalServices.value.map(service => service.id)
              const newServicesIds = serviceIds.split(',')
              const servicesIdsWithoutRemoval = newServicesIds.filter(el => !gelRemovalServicesIds.includes(el) )

              const sameArtist = appointment.sameArtist.toString()
              root.$router.push({
                name: routeName,
                query: {
                  userId,
                  personsValues,
                  serviceIds: servicesIdsWithoutRemoval.join(','),
                  sameArtist: sameArtist,
                  persons: persons.toString(),
                  hands: personsObject.value.mani.toString(),
                  feet: personsObject.value.pedi.toString(),
                }
              })
            }
          })

          // const charge = (result.amount / 100).toFixed(2)
          // root.$alert(`Thank you for your purchase. Your credit card was charged $${charge}.`, "Success", {
          //   confirmButtonText: "OK",
          //   async callback() {
          //     const appointment = root.$store.state.appointments.possibleAppointment
          //     if (!appointment) {
          //       if (props.adminArea) {
          //         root.$router.push({ name: "!-users-show", params: { id: props.userId } })
          //       } else {
          //         await root.$store.dispatch("auth/refreshViewer")
          //         root.$router.push({ name: "*-account" })
          //       }
          //       return
          //     }
          //     const { successful, result, messages } = await root.$store.dispatch("appointments/createAppointment")
          //     if (successful) {
          //       root.$router.push({ name: "*-services-confirmed", params: { id: result.id } })
          //       return
          //     }
          //     const message = messages[0].message
          //     alert(message)

          //     const routeName =
          //       viewer.value.id === appointment.userId ? "*-services-availability" : "!-services-availability"
          //     const userId = appointment.userId
          //     const serviceIds = appointment.serviceIds.join(",")
          //     const sameArtist = appointment.sameArtist.toString()
          //     root.$router.push({
          //       name: routeName,
          //       query: { userId, serviceIds, sameArtist: sameArtist }
          //     })
          //   }
          // })
        } else {
          root.$alert(messages[0].message, "Charge failed", {
            confirmButtonText: "OK"
          })
          submitting.value = false
        }
      } catch (e) {
        submitting.value = false
        alert(e)
      }
    }

    return {
      handleMembershipSelect,
      memberships,
      selectedMembershipId,
      ///
      handlePackChange,
      //
      handlePurchase,
      handleDiscountPrice,
      totalPrice,
      submitting,
      //
      user,
      handleAdded,
      //
      maniDrawerRef,
      pediDrawerRef,
      discountCodeRef,
      discountCodeFoundRef,
      clearDiscountStuff,
      discountIconRef,
      discountPriceRef,
      //
      gelRemovalServices,
      manicureServices,
      pedicureServices,
      removalPacks,
      manicurePacks,
      pedicurePacks,
      checkPacksCounts
    }
  }
})
