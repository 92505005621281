










import { createComponent } from "@vue/composition-api"
import PackSelector from "@/components/admin/packs-selector.vue"

export default createComponent({
  components: {
    PackSelector
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(_props, { root }) {
    const query = (root.$route.query.services || "") as string
    const services = query.length > 0 ? query.split(",") : []
    return {
      services
    }
  }
})
