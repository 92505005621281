import { format, parseISO } from "date-fns"

// sleep forever
export function death(): Promise<never> {
  return new Promise(() => false)
}
// to sleep for 2 seconds: await sleep(2000)
export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const pronounMap = {
  HE: "He/him",
  SHE: "She/her",
  THEY: "They/their"
}

export function formatAmount(amount) {
  return `$${(amount / 100).toFixed(2)}`
}

export function formatPronoun(pronoun) {
  return pronounMap[pronoun]
}

export function formatDateShort(date_or_string) {
  const date = typeof date_or_string === "string" ? parseISO(date_or_string) : date_or_string
  return format(date, "d-MMM-yyyy")
}
