






























































import { createComponent, ref, onMounted } from "@vue/composition-api"
import FilterByArtist from "@/components/common/filter-by-artist.vue"
import { listLocations } from "@/api/locations"

export default createComponent({
  components: {
    FilterByArtist
  },
  props: {
    availableArtists: {
      type: Array,
      required: true
    },
    selectedArtists: {
      type: Array,
      required: true
    },
    date: {
      type: Date,
      required: true
    },
    timeRange: {
      type: Array,
      required: true
    },
    location: {
      type: String,
      required: false
    }
  },
  setup(props, { emit }) {
    const filterDrawerRef = ref(null)
    const selectedTime = ref(null)
    const selectedLocation = ref("")
    const locations = ref([])

    async function fetchLocations() {
      locations.value = await listLocations()
      if (props.location) {
        selectedLocation.value = props.location
      }
    }

    onMounted(() => {
      fetchLocations()
    })

    // date changer
    function handleDateChange(newDate) {
      emit("update:date", newDate)
    }

    const timeMarks = [
      { value: 28, label: '7:00 am' },
      { value: 29, label: '7:15 am' },
      { value: 30, label: '7:30 am' },
      { value: 31, label: '7:45 am' },
      { value: 32, label: '8:00 am' },
      { value: 33, label: '8:15 am' },
      { value: 34, label: '8:30 am' },
      { value: 35, label: '8:45 am' },
      { value: 36, label: '9:00 am' },
      { value: 37, label: '9:15 am' },
      { value: 38, label: '9:30 am' },
      { value: 39, label: '9:45 am' },
      { value: 40, label: '10:00 am' },
      { value: 41, label: '10:15 am' },
      { value: 42, label: '10:30 am' },
      { value: 43, label: '10:45 am' },
      { value: 44, label: '11:00 am' },
      { value: 45, label: '11:15 am' },
      { value: 46, label: '11:30 am' },
      { value: 47, label: '11:45 am' },
      { value: 48, label: '12:00 pm' },
      { value: 49, label: '12:15 pm' },
      { value: 50, label: '12:30 pm' },
      { value: 51, label: '12:45 pm' },
      { value: 52, label: '1:00 pm' },
      { value: 53, label: '1:15 pm' },
      { value: 54, label: '1:30 pm' },
      { value: 55, label: '1:45 pm' },
      { value: 56, label: '2:00 pm' },
      { value: 57, label: '2:15 pm' },
      { value: 58, label: '2:30 pm' },
      { value: 59, label: '2:45 pm' },
      { value: 60, label: '3:00 pm' },
      { value: 61, label: '3:15 pm' },
      { value: 62, label: '3:30 pm' },
      { value: 63, label: '3:45 pm' },
      { value: 64, label: '4:00 pm' },
      { value: 65, label: '4:15 pm' },
      { value: 66, label: '4:30 pm' },
      { value: 67, label: '4:45 pm' },
      { value: 68, label: '5:00 pm' },
      { value: 69, label: '5:15 pm' },
      { value: 70, label: '5:30 pm' },
      { value: 71, label: '5:45 pm' },
      { value: 72, label: '6:00 pm' },
      { value: 73, label: '6:15 pm' },
      { value: 74, label: '6:30 pm' },
      { value: 75, label: '6:45 pm' },
      { value: 76, label: '7:00 pm' },
      { value: 77, label: '7:15 pm' },
      { value: 78, label: '7:30 pm' },
      { value: 79, label: '7:45 pm' },
      { value: 80, label: '8:00 pm' },
    ]

    function handleTimeRangeChange(newTimeRange) {
      emit("update:timeRange", newTimeRange)
    }

    function handleTimeDropdownChange(value) {
      selectedTime.value = value
      handleTimeRangeChange([value, 80])
    }

    function handleLocationDropdownChange(value) {
      selectedLocation.value = value
      emit("locationChange", value)
    }

    function handleArtistRemove(artist) {
      const artists = [...props.selectedArtists]
      const index = artists.indexOf(artist)
      artists.splice(index, 1)
      emit("update:selectedArtists", artists)
    }

    function handleNewSelected(newSelected) {
      emit("update:selectedArtists", newSelected)
    }

    return {
      selectedTime,
      handleArtistRemove,
      handleDateChange,
      handleNewSelected,
      handleTimeRangeChange,
      handleLocationDropdownChange,
      filterDrawerRef,
      locations,
      selectedLocation,
      handleTimeDropdownChange,
      timeMarks,
    }
  }
})
