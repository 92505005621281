



















































































































































import { createComponent, onMounted, ref, computed } from "@vue/composition-api"
import { listServiceCategories } from "@/api/menu_service_categories"
import { listNailartCategory } from "@/api/menu_nailart_category"
import { listAddons } from "@/api/menu_addons"

export default createComponent({
  setup() {
    const serviceCategories = ref([])
    const addons = ref([])
    const nailarts = ref([])

    onMounted(async () => {
      serviceCategories.value = await listServiceCategories()
      addons.value = await listAddons()
      nailarts.value = await listNailartCategory()
    })

    function toggleServiceCategory (ref) {
      if (Array.isArray(this.$refs[ref])) {
        if (this.$refs[ref][0].classList.contains('service--active')) {
          this.$refs[ref][0].classList = 'service'
        } else {
          this.$refs[ref][0].classList.add('service--active')
          setTimeout(() => {
            this.$refs[ref][0].classList.add('service--visible')
          }, 200)
        }
      } else {
        if (this.$refs[ref].classList.contains('service--active')) {
          this.$refs[ref].classList = 'service'
        } else {
          this.$refs[ref].classList.add('service--active')
          setTimeout(() => {
            this.$refs[ref].classList.add('service--visible')
          }, 200)
        }
      }
    }

    const serviceCategoriesWithoutMemberships = computed(() => {
      return serviceCategories.value.filter(category => category.memberships.length === 0)
    })

    const serviceCategoriesWithMemberships = computed(() => {
      return serviceCategories.value.filter(category => category.memberships.length > 0)
    })

    return {
      serviceCategories,
      addons,
      nailarts,
      serviceCategoriesWithoutMemberships,
      serviceCategoriesWithMemberships,
      toggleServiceCategory
    }
  }
})
