







import { createComponent } from "@vue/composition-api"
import { MembershipOption } from "@/generated/graphql"

export default createComponent({
  props: {
    membership: {
      type: Object as () => MembershipOption,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: false
    }
  },
})
