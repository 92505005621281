






import { computed, createComponent } from "@vue/composition-api"
import ServiceContainer from "@/components/client/services-container.vue"

export default createComponent({
  components: {
    ServiceContainer
  },
  setup(_props, { root }) {
    const user = computed(() => root.$store.state.auth.user)
    return { user }
  }
})
