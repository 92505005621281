












































import { createComponent, ref, Ref, watch } from "@vue/composition-api"
import { addMonths, endOfDay, startOfDay } from "date-fns"
import { generateReport } from "@/api/purchases"
import { Maybe, Report } from "@/generated/graphql"

// Convert a row of rows to a valid CSV string. Assumes each value is a string.
function toCSVString(rows) {
  return rows.map(row => row.map(value => `\"${value.replace(/\"/g, '""')}\"`).join(",")).join("\r\n")
}

// Create download link and click it.
function download(filename, text) {
  const element = document.createElement("a")
  element.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(text))
  element.setAttribute("download", filename)
  element.style.display = "none"
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

const reportIdOptions = [
  { label: "Artist Services", value: "artist-services" },
  { label: "Newsletter", value: "newsletter" },
  { label: "Sign Ups", value: "sign-ups" },
  { label: "Transactions", value: "transactions" },
  { label: "Cancelled Appointments", value: "cancelled-appointments" }
]

export default createComponent({
  setup() {
    const now = startOfDay(new Date())
    const reportIdRef = ref(null)
    const dateRangeRef = ref([addMonths(now, -1), now])
    const reportRef: Ref<Maybe<Report>> = ref(null)

    watch(async () => {
      const startAt = startOfDay(dateRangeRef.value[0] as Date)
      const endAt = endOfDay(dateRangeRef.value[1] as Date)
      if (reportIdRef.value) {
        console.log(`Fetch report ${reportIdRef.value} from ${startAt}-${endAt}`)
        reportRef.value = await generateReport({ id: reportIdRef.value, startAt, endAt })
      }
    })

    function handleExport() {
      const report = reportRef.value
      const csv = toCSVString([report.columns].concat(report.rows))
      download(`${report.name}.csv`, csv)
    }

    return {
      reportIdOptions,
      // reactive refs
      dateRange: dateRangeRef,
      report: reportRef,
      reportId: reportIdRef,
      // methods
      handleExport
    }
  }
})
